import styled from "styled-components";

export const PageWrapper = styled.div`
  max-width: 1570px;
  margin: 0 auto;
  width: 100%;
  padding: 0 60px;

  @media screen and (max-width: 800px) {
    padding: 0 20px;
  }
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
`;
