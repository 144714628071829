import styled from "styled-components";

const Cubes = ({ center }) => (
  <CubeWrapper center={center}>
    <Cube />
    <Cube />
  </CubeWrapper>
);

const CubeWrapper = styled.div`
  display: flex;
  justify-content: ${({ center }) => center && "center"};
`;
const Cube = styled.div`
  width: 10px;
  height: 10px;
  & + & {
    margin-left: 5px;
  }
  &:first-child {
    background: #ff6969;
  }
  &:nth-child(2) {
    background: #f2bb91;
  }
`;

export default Cubes;
