const terms = {
  personal: {
    title: "개인정보처리방침",
    __html: ` 애니메디솔루션주식회사(이하“애니메디”라 함)가 취급하는 모든 개인정보는 관련 법령에 근거하거나 정보주체의 동의에 의하여 수집, 보유 및 처리되고 있습니다.
    
  본 방침은 2020년 3월 26일 부터 시행됩니다.   
  
  애니메디는 법령의 규정에 따라 수집, 보유 및 처리하는 개인정보를 정보주체의 권익을 보호하기 위하여 적법하고 적정하게 취급할 것입니다.
  또한, 애니메디는 관련 법령에서 규정한 바에 따라 애니메디에서 보유하고 있는 개인정보에 대한 열람청구권 및 정정청구권 등 이용자의 권익을 존중하며, 여러분은 이러한 법렵상 권익의 침해 등에 대하여 행정심판법에서 정하는 바에 따라 행정심판을 청구할 수 있으며, 개인정보분쟁조정위원회, 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다.
  애니메디의 개인정보 처리방침은 현행 「개인정보보호법」 및 「정보통신망 이용촉진 및 정보보호등에 관한 법률」에 근거를 두고 있습니다. 이 방침은 별도의 설명이 없는 한 애니메디에서 운용하는 모든 홈페이지에 적용됨을 알려드립니다.
  다만, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관계 법령에 의한 소관 업무를 위하여 애니메디 내 담당 조직에서 별도의 개인정보 처리방침을 개정, 시행하는 경우 이를 따르고, 이를 해당 조직이 운영하는 홈페이지에 게시함을 알려드립니다.
  
  제 1 조 [개인정보의 처리목적]
  애니메디는 개인정보를 다음의 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경될 경우에는 동의를 받아 처리 하겠습니다.
  ◦ 홈페이지 회원가입 및 관리
  회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별, 인증, 회원자격 유지, 관리, 서비스 부정이용 방지, 각종 고지, 통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
  ◦ 개인정보의 수집 및 보유항목
  애니메디에서 제공하는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집하며, 이에 대한 동의를 얻고 있습니다. 수집하는 개인정보 항목은 다음과 같습니다.
  
   <table style="width: 100%">
      <tr>
        <th>구분</th>
        <th>수집항목</th>
        <th>보유기간</th>
      </tr>
      <tr>
        <td>홈페이지 회원가입</td>
        <td>성명, 전화번호(휴대폰번호), Email주소</td>
        <td>회원탈퇴 시 까지</td>
      </tr>
  </table>
  
  제 2 조 [개인정보 취급방침]
   ◦ 개인정보의 제공
   애니메디가 수집, 보유하고 있는 개인정보는 이용자의 동의 없이는 제3자에게 제공하지 않으며, 다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
   1) 정보주체로부터 별도의 동의를 받은 경우
   2) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
   3) 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
   4) 다음 각 호의 어느 하나에 해당하는 경우에는 정보주체 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 이용자의 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수 있습니다.
    - 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
    - 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의,의결을 거친 경우
     - 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우
     - 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
     - 법원의 재판업무 수행을 위하여 필요한 경우
     - 형(刑 ) 및 감호, 보호처분의 집행을 위하여 필요한 경우
  
  제 3 조 [개인정보 주체의 권리, 의무 및 행사방법]
   ◦ 정보주체의 권리, 의무 및 그 행사방법
   이용자는 정보 주체로서 다음과 같은 권리를 행사할 수 있습니다.
   1) 개인정보 열람 요구 : 애니메디에서 보유하고 있는 개인정보는 「개인정보보호법」 제35조(개인정보의 열람)에 따라 열람을 요구할 수 있습니다. 다만 개인정보 열람 요구는 「개인정보보호법」 제35조제5항에 의하여 다음과 같이 제한될 수 있습니다.
   2) 개인정보 정정·삭제 요구 : 애니메디에서 보유하고 있는 개인정보에 대해서는 「개인정보보호법」 제36조(개인정보의 정정· 삭제)에 따라 애니메디에 개인정보의 정정·삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
   3) 개인정보 처리정지 요구 : 애니메디에서 보유하고 있는 개인정보에 대해서는 「개인정보보호법」 제37조(개인정보의 처리정지 등)에 따라 애니메디에 개인정보의 처리정지를 요구할 수 있습니다. 또한 만 14세 미만 아동의 법정대리인은 애니메디에 그 아동의 개인정보의 열람, 정정·삭제, 처리정지 요구를 할 수 있습니다. 다만, 개인정보 처리정지 요구시 「개인정보보호법」 제37조제2항에 의하여 처리정지 요구가 거절될 수 있습니다.
    가. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
    나. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
    다. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
   4) 개인정보의 열람, 정정·삭제, 처리정지 요구에 대해서는 10일 이내에 해당 사항에 대한 애니메디의 조치를 통지합니다.
   5) 개인정보의 열람, 정정, 삭제, 처리정지 요구 접수,처리 부서와 담당자는 아래와 같습니다
    - 부서 : 애니메디솔루션주식회사 사업부
    - 담당자 : 홍유진 (TEL. 070 - 7700 - 6606 )
    - 주소 : 서울 송파구 올림픽로 35길 137, 11층 2호
  
   제 4 조 [개인정보의 파기]
    애니메디는 원칙적으로 개인정보의 보유기간이 경과했거나 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
   1) 파기절차이용자가 입력한 정보는 보유기간이 경과했거나 처리목적이 달성 후 내부 방침 및 관련 법령에 따라 파기합니다.
   2) 파기기한이용자의 개인정보는 회원탈퇴 시 즉시 파기되며 개인정보의 처리 목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 7일 이내에 그 개인정보를 파기합니다.
   3) 파기방법
    애니메디에서 처리하는 개인정보를 파기할 때에는 다음의 방법으로 파기합니다.
    가. 전자적 파일 형태인 경우 : 복원이 불가능한 방법으로 연구삭제
    나. 전자적 파일 형태 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우 : 파쇄 또는 소각
  
   제 5 조 [개인정보의 안전성 확보 조치]
    애니메디는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
   1) 내부관리계획의 수립 및 시행 관련 애니메디는 ‘개인정보의 안전성 확보조치 기준’(행정안전부고시 제2019 - 제47호)에 의거하여 내부관리계획을 수립 및 시행합니다.
   2) 개인정보취급자 지정의 최소화 및 교육개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고 있습니다.
   3) 개인정보에 대한 접근 제한개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근을 통제하고, 침입차단시스템과 탐지시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다. 또한 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 3년간 보관하고 있습니다.
   4) 접속기록의 보관 및 위변조 방지개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 관리하고 있습니다.
   5) 개인정보의 암호화이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.
   6) 해킹 등에 대비한 기술적 대책기술원은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.
   7) 비인가자에 대한 출입 통제개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
  
   제 6 조 [개인정보 보호책임자]
    애니메디는 개인정보를 보호하고 개인정보와 관련된 사항을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.
  
  <table style="width: 100%">
    <tr>
      <th colspan="2">개인정보 보호책임자</th>
      <th colspan="2">개인정보 보호담당자</th>
    </tr>
    <tr>
      <td>담당부서</td>
      <td>사업부</td>
      <td>담당부서</td>
      <td>사업부</td>
    </tr>
    <tr>
      <td>성명</td>
      <td>박현위</td>
      <td>성명</td>
      <td>홍유진</td>
    </tr>
    <tr>
      <td>전화</td>
      <td>070-4464-0987</td>
      <td>전화</td>
      <td>070-7700-6606</td>
    </tr>
    <tr>
      <td>이메일</td>
      <td>hwpark@anymedi.com</td>
      <td>이메일</td>
      <td>mkt.hong@anymedi.com</td>
    </tr>
  </table>
  
  제 7 조 [개인정보 처리방침의 변경]
    이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
  
   제 8 조 [권익침해 구제방법]
    정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
   1) 개인정보분쟁조정위원회 : (국번없이) 118(내선2번)
   2) 대검찰청 사이버범죄수사단 : 02-3480-3571 (http://www.spo.go.kr/)
   3) 경찰청 사이버테러첨단기술원 : 1566-0112 (http://www.netan.go.kr/)
  
  「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정· 삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
  
  ※ 행정심판에 대해 자세한 사항은 법제처 (http://www.moleg.go.kr) 홈페이지를 참고하시기 바랍니다.
   
  
   `,
  },
  email: {
    title: "이메일 주소 수집거부",
    __html: `웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 의해 처벌 받을 수 있습니다.`,
  },

  manual: {
    title: "사용 방법 및 주의사항",
    __html: `사용설명서
    품목명 : 고형이식의료용실리콘재료
    
    1. 제품 설명
    본 제품은 코의 성형 및 치료를 목적으로 인체에 삽입하여 사용하는 의료용 실리콘 재료이다. 제품은 안면부 코의 확대 성형 및 재건 성형에 사용되며, 환자의 결손부 모양에 따라 허가 받은 범위 내에서 개인 맞춤형으로 제조된다.
      
    2. 사용목적
    본 제품은 코의 성형 및 치료를 목적으로 인체에 삽입되는 이식형의 단단한 재료이다. 
      
    3. 사용방법 및 사용시 주의사항
    (1)	사용 전 준비사항
      1) 본 제품은 환자의 영상 데이터(CT 또는 MRI)를 기반으로 맞춤형 형상을 모델링하여 제작되고 사용하는 제품임을 확인한다.
      2) 사용 전에 의사 처방 유무를 확인하고, 처방과 제품에 적용된 환자 병변 데이터 일치 여부 등을 확인한다.
      3) 해당 제품의 포장용기가 파손된 곳은 없는지 점검한다.
      4) 제품의 구성품 중 누락 부분이 있는지 확인한다.
      5) 본 제품은 멸균된 상태로 공급되므로 포장 재질의 파손이 없는지 확인하여 멸균된 제품만을 사용하여야 한다.
      6) 의사는 본 제품의 수술기구를 이용한 수술방법과 임상적응증, 금기사항 등에 대하여 완벽하게 숙지하고 있어야 한다.
      7) 수술결과를 저해할 수 있는 포장의 손상, 이물질이 없는지 확인한다. 손상이나 이물질 확인 시, 제조원에 전달 후 폐기한다.
      8) 사용설명서를 잘 읽은 후 사용한다.
    (2)	사용방법
      1) 삽입할 위치에 알맞은 사이즈인지 제품과 비교하여 확인한다.
      2) 삽입할 위치에 incision 표시를 한 후 incision을 한다.
      3) 삽입할 위치에 해당 제품을 삽입하여 위치를 고정시킨다.
      4) 삽입 후에 이물질이나 출혈이 있는지 확인한 뒤 suture 한다.   
    (3)	사용 후 보관 및 관리방법
      1) 본 제품은 일회용이므로 재사용을 금하며, 의료용 폐기물 처리규정에 따라 폐기한다
    
    
    4. 사용시 주의사항
    (1) 사용시 주의사항
    환자에 대한 수술 전 평가 시, 의사는 다음 사항들을 고려하여 수술이나 수술 후 합병증의 위험을 높일 수 있는 신체적 상태가 있는지를 평가해야 한다.
      1) 임플란트를 시술할 부위의 최근 발생한 농이나 종양, 암의 재발이나 전이
      2) 정신적 불안정 상태
      3) 이물질에 대한 과민반응의 경험과 심각한 알러지
      4) 임플란트를 시행할 부위의 표면조직이 적절치 않은 경우
      5) 임플란트를 하고 조기에 불만족을 경험한 경우
      6) 당뇨
      7) 자가면역 질환
      8) 응고병증
      9) 폐질환과 심혈관계 문제
      10) 전신마취 혹은 부분마취에 대한 거부반응  
    (2) 중요한 권장사항 및 경고
      1) 본 제품은 의사나 의사의 처방 하에서만 구입할 수 있다.
      2) 수술 중 제품의 멸균상태를 유지하기 위해 제품을 사용할 때 무균법과 청결함 유지가 필요하다.
      3) 본 제품을 사용하기 전까지 본 제품과 포장은 외관상 온전한 상태로 유지 및 보관되어야 하며, 그렇지 않다면 해당 제품은 사용하지 않아야 한다.
      4) 본 제품은 환자맞춤형 제품이므로, 제품의 모델명을 확인하여 해당 환자와 일치하는 제품인지 필히 확인한다.
      5) 본 제품의 표면이 이물질, 먼지 혹은 기름으로 오염되어서는 안된다.
      6) 본 제품이 날카로운 수술용 기구 등에 접촉되지 않도록 주의한다.
      7) 수술 전 혹은 수술 중 손상된 제품은 복구 또는 재사용하지 않는다.
      8) 임의로 제품에 물리적인 변형을 가하여 사용하지 않는다.  
    (3) 금기사항
      1) 본 제품의 적용대상 부위 이외의 다른 수술 또는 시술에 사용하지 않는다.
      2) 수술 또는 시술 부위에 염증이 있거나 다른 감염이 우려된 경우 금기하여야 한다.  
    (4) 부작용
      1) 알러지 반응
      2) 본 제품이 삽입된 부위의 감각의 변화
      3) 시술 중 좌골신경등이 자극 혹은 손상되어 발생하는 신경학적 증상
      4) 감염
      5) 피막구축
      6) 부정확한 삽입으로 인한 부정교합 및 불편감
      7) 피부궤양
      8) 기타 면역학적 반응  
    (5) 경고 또는 주의사항
      1) 본 제품은 수술 및 시술에 능숙한 숙련된 의사가 사용하여야 한다.
      2) 본 제품을 이용한 성형 효과의 지속 기간은 정해져 있지 않으며, 환자에게 제품의 제거 혹은 재수술, 재시술의 가능성에 대해 설명하여야 한다.
      3) 환자는 이식에 따른 위험과 이점에 대해 충분한 상담을 받아야 한다.
      4) 수술과 관련된 합병증에 대해 환자에게 충분한 설명이 이루어져야 한다.
    
    5. 저장방법
      1) 직사광선을 피하고 서늘한 환경에 보관한다.
      2) 건조한 실온(30°C이하)에서 보관한다.
      3) 포장상태 : 비닐파우치에 1차 포장 후 종이 box로 2차 포장된 상태
    
    6. 포장단위
      1EA / pack
  
    7. 사용기간
      제조일로부터 6개월
    
                                            Rev. 1_2020.02
    `,
  },
};

export default terms;
