import styled from "styled-components";
import { Link } from "react-router-dom";

import { PageWrapper } from "../../atoms";
import { Cubes } from "../../molecules";
import { WhiteButton } from "../../molecules/buttons";

import background from "../../../../assets/images/landing/softwareMob.png";

const SoftwareComponent = () => (
  <Software>
    <PageWrapper>
      <SoftwareWrapper>
        <SectionTitle>SOFTWARE</SectionTitle>
        <SectionSubtitle>기술에 상상을 더합니다.</SectionSubtitle>
        <Main>
          <Cubes right />
          <Content>
            이노핏 가상성형 소프트웨어는<br />
            3D 페이스 스캐닝을 통해<br />
            내 얼굴에 어울리는 모양과 라인을<br />
            직접 디자인해볼 수 있는<br />
            3D 가상성형 소프트웨어입니다.
          </Content>
        </Main>
        <Link to="/mobile/software">
          <WhiteButton name="소프트웨어 다운로드" />
        </Link>
        <DownloadButtonSubcontent>* PC에서만 다운로드 가능합니다.</DownloadButtonSubcontent>
      </SoftwareWrapper>
    </PageWrapper>
  </Software>
);

const Software = styled.div`
  padding: 140px 0;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 800px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 420px) {
    padding: 80px 0px;
  }
`;

const SoftwareWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
  @media screen and (max-width: 420px) {
    text-align: right;
  }
`;

const SectionTitle = styled.p`
  color: #fff;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
  @media screen and (max-width: 420px) {
    font-size: 44px;
    line-height: 42px;
  }
`;
const SectionSubtitle = styled.p`
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  margin-top: 20px;
  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.05em;
    font-weight: 400;
    margin-top: 13px;
  }
`;

const Main = styled.div`
  margin-top: 50px;
  margin-bottom: 76px;
  @media screen and (max-width: 800px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 420px) {
    margin-bottom: 55px;
  }
`;
const Content = styled.p`
  font-weight: 200;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #f3f3f3;
  margin-top: 16px;
  @media screen and (max-width: 420px) {
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.05em;
    color: #F3F3F3;
    margin-top: 14px;
  }
`;
const DownloadButtonSubcontent = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #d8d8d8;
  margin-top: 16px;
`;

export default SoftwareComponent;
