import { useState } from "react";
import styled from "styled-components";
import { PageWrapper } from "../../atoms";
import { Chapter, Cubes } from "../../molecules";
import { BeigeButton } from "../../molecules/buttons";

import video from "../../../assets/images/landing/video.png";

const VideoComponent = () => {
  const [showYoutube, setYoutube] = useState(false);
  return (
    <Video>
      <PinkBackground />
      <PageWrapper>
        <VideoWrapper>
          <Left>
            <SectionTitle>이노핏 TV</SectionTitle>
            <SectionSubTitle>이노핏 유튜브 영상</SectionSubTitle>
            <Cubes />
            <Content>
              이노핏의 새로운 소식,
              <br />
              영상으로 만나보세요.
            </Content>
            <a
              href="https://www.youtube.com/channel/UCHkPsqN-Sde1dw_WScBeW2Q"
              rel="noreferrer"
              target="_blank"
              className="btn-youtube"
            >
              <BeigeButton
                name="유튜브 채널 바로가기"
              />
            </a>
          </Left>
          <Right>
            <Chapter name="CHAPTER 03" />
            {/* 이미지 클릭 시 이미지 사라지고 유튜브 자동재생 */}
            <VideoImage
              src={video}
              onClick={() => setYoutube(true)}
              isShow={!showYoutube}
            />
            {showYoutube && (
              <Youtube>
                <iframe
                  width="100%"
                  height="340"
                  src="https://www.youtube.com/embed/MOUuWBrWwsQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Youtube>
            )}
          </Right>
        </VideoWrapper>
      </PageWrapper>
    </Video>
  );
};

const PinkBackground = styled.div`
  position: absolute;
  background: #f7dada;
  border-radius: 0px 160px 160px 0px;
  height: 540px;
  width: calc(75vw - 40px);
  z-index: -1;
  top: 0;
  @media screen and (max-width: 800px) {
    width: 95vw;
    height: 630px;
    border-radius: 0px 100px 100px 0px;
  }
`;

const Video = styled.div`
  position: relative;
  padding-top: 100px;
`;
const VideoWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const SectionTitle = styled.p`
  font-family: "S-Core Dream";
  font-weight: bold;
  font-size: 45px;
  line-height: 42px;
  letter-spacing: -0.05em;
`;
const SectionSubTitle = styled.p`
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 300;
`;

const Left = styled.div`
  margin-right: 20px;
  flex: 1;
  max-width: 535px;
  @media screen and (max-width: 800px) {
    margin-bottom: 50px;
  }
`;
const Right = styled.div`
  width: 100%;
  max-width: 612px;
`;

const Content = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 15px;
  margin-bottom: 60px;
`;

const VideoImage = styled.img`
  margin-top: 22px;
  cursor: pointer;
  display: ${({ isShow }) => !isShow && "none"};

  @media screen and (max-width: 1450px) {
    width: 85%;
  }
`;
const Youtube = styled.div`
  margin-top: 22px;
`;
export default VideoComponent;
