import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import $ from "jquery";

import arrow from "../../../assets/images/icons/to-top-arrow.png";

const ToTopButtonComponent = () => {
  const [windowScrollTop, setWindowScrollTop] = useState(0);

  useEffect(() => {
    // 마우스 따라오는 애니메이션
    // 출처 https://jyou.media/
    $(".menu-wrapper").on("mouseenter", function () {
      $(this).addClass("is-over is-loaded");
    });

    $(".menu-wrapper").on("mouseleave", function () {
      $(this).removeClass("is-over").attr("style", "");
    });

    $(".menu-wrapper").on("mousemove", function (e) {
      if ($(".menu-wrapper.is-over").length) {
        var h = $(this).outerHeight();
        var w = $(this).outerWidth();
        var x = e.clientX - this.offsetLeft;
        var y = e.clientY - this.offsetTop;

        var rotateVal = (w / 2 - x) * -0.1;
        var translateXVal = (w / 2 - x) * -0.2;
        var translateYVal = (h / 2 - y) * -0.2;

        $(this).css({
          transform:
            "translate(" +
            translateXVal +
            "px, " +
            translateYVal +
            "px) rotateZ(" +
            rotateVal +
            "deg)",
        });
      }
    });
  }, []);

  useEffect(() => {
    // 스크롤 맨 위면 to top 버튼 사라짐
    const handleScroll = () => {
      const { scrollTop } = window.document.querySelector("html");
      setWindowScrollTop(scrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // 스크롤 맨 위로
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ButtonWrapper className="menu-wrapper" scrollTop={windowScrollTop}>
      <ToTopButton onClick={scrollToTop}>
        <Arrow src={arrow} />
        <Top>TOP</Top>
        <BorderBG viewBox="0 0 60 60" width="60" height="60">
          <circle vectorEffect="non-scaling-stroke" cx="30" cy="30" r="29" />
        </BorderBG>
        <Border viewBox="0 0 60 60" width="60" height="60">
          <circle vectorEffect="non-scaling-stroke" cx="30" cy="30" r="29" />
        </Border>
      </ToTopButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: ${({ scrollTop }) => (scrollTop === 0 ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 5.5%;
  bottom: 56px;
  z-index: 10;
  transition: width 0.4s, height 0.4s, transform 0.1s;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const Arrow = styled.img``;
const Top = styled.span`
  font-family: "Saira Condensed";
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #383838;
`;

const drawCircle = keyframes`
    0% {
      transform: rotateZ(-90deg); }
    to {
      transform: rotateZ(90deg);
      stroke-dashoffset: 0; } 

`;

const undrawCircle = keyframes`  

  0% {
    transform: rotate(90deg);
    stroke-dashoffset: 0; }
  to {
    transform: rotate(270deg);
    stroke-dashoffset: -251.33px; }`;

const Border = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  circle {
    transform-origin: 50% 50%;
    fill: none;
    stroke: #f78b8b;
    stroke-opacity: 1;
    stroke-dasharray: 251.33px 251.33px;
    stroke-dashoffset: 251.33px;
    stroke-width: 2px;
    visibility: 0;
    transition: visibility 0s 1.5s;
    animation-name: ${undrawCircle};
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const ToTopButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  :hover {
    color: transparent;
    outline: 0;
    background: 0 0;

    ${Border} {
      circle {
        animation-name: ${drawCircle};
        animation-duration: 2.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
`;

const BorderBG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  circle {
    fill: #fff;
    stroke: rgba(10, 10, 10, 0.1);
  }
`;

export default ToTopButtonComponent;
