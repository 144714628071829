import { useState, useEffect } from "react";
import axios from 'axios'
import moment from 'moment'
import styled from "styled-components";

import toRight from "../../assets/images/icons/news-to-right.png";
import toLeft from "../../assets/images/icons/news-to-left.png";

const NewsItemComponent = ({ news }) => (
  <NewsItemWrapper>
    <a href={news.link} target="_blank" rel="noreferrer">
      <NewsItem>
        <NewsTop>
          <NewsThumbnail src={news.image} />
        </NewsTop>
        <NewsBottom>
          <NewsTitle>{news.title}</NewsTitle>
          <NewsContent>{news.contents}</NewsContent>
          <NewsDate>{moment(news.regdate).format('YYYY-MM-DD')}</NewsDate>
        </NewsBottom>
      </NewsItem>
    </a>
  </NewsItemWrapper>
);

const NewsItemWrapper = styled.div`
  width: 33.33%;
  padding-left: 40px;
  @media screen and (max-width: 1450px) {
    padding-left: 30px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const NewsThumbnail = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;
const NewsTop = styled.div``;
const NewsBottom = styled.div`
  padding: 29px 24px;
  background: #fff;
  @media screen and (max-width: 420px) {
    padding: 32px 26px 23px 26px;
  }
`;
const NewsTitle = styled.p`
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const NewsContent = styled.p`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (max-width: 420px) {
    margin-top: 11px;
  }
`;
const NewsDate = styled.p`
  font-family: "Saira Condensed";
  font-size: 15px;
  line-height: 20px;
  color: #ff6969;
  margin-top: 24px;
  @media screen and (max-width: 420px) {
    margin-top: 26px;
  }
`;

const Pagination = ({ startIndex, setStartIndex, perPage, list }) => {
  const handlePageDown = () => {
    setStartIndex((prev) => prev - perPage);
  };
  const handlePageUp = () => {
    setStartIndex((prev) => prev + perPage);
  };
  const nowPage = parseInt(startIndex / perPage) + 1;
  const totalPage = Math.ceil(list.length / perPage);
  return (
    <PaginationWrapper>
      {/* 페이지 뒤로 */}
      <ToLeftButton onClick={handlePageDown} isShow={nowPage !== 1}>
        <ToLeft src={toLeft} />
      </ToLeftButton>
      {/* 현재페이지 / 전체페이지 */}
      <NowPage>{nowPage}</NowPage> / <TotalPage>{totalPage}</TotalPage>
      {/* 페이지 앞으로 */}
      <ToRightButton onClick={handlePageUp} isShow={nowPage !== totalPage}>
        <ToRight src={toRight} />
      </ToRightButton>
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Saira Condensed";
  font-size: 20px;
  line-height: 20px;
  color: #989898;
  margin-top: 65px;

  @media screen and (max-width: 420px) {
    margin-top: 50px;
  }
`;

const ToLeftButton = styled.button`
  margin-right: 10px;
  visibility: ${({ isShow }) => !isShow && "hidden"};
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  width: 26px;
  height: 26px;
`;
const ToLeft = styled.img``;
const NowPage = styled.span`
  color: #383838;
  font-family: "Saira Condensed";
  margin-right: 3px;
`;
const TotalPage = styled.span`
  font-family: "Saira Condensed";
  margin-left: 3px;
`;

const ToRightButton = styled.button`
  visibility: ${({ isShow }) => !isShow && "hidden"};
  border-radius: 50%;
  margin-left: 10px;
  width: 26px;
  height: 26px;
  border: 1px solid #d8d8d8;
`;

const ToRight = styled.img``;

const NewsComponent = ({ perPage, mobile = false }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [list, setList] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/News`).then(item => {
      setList(item.data)
    })
  }, [])

  return (
    <NewsWrapper mobile={mobile}>
      <NewsList>
        {list.slice(startIndex, startIndex + perPage).map((n, i) => (
          <NewsItemComponent news={n} key={i} />
        ))}
      </NewsList>
      <Pagination
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        perPage={perPage}
        list={list}
      />
    </NewsWrapper>
  );
};

const News = () => {
  return (
    <>
      <NewsComponent perPage={3} />
      <NewsComponent perPage={1} mobile />
    </>
  );
};

const NewsWrapper = styled.div`
  margin-top: 60px;
  display: ${({ mobile }) => mobile && "none"};
  @media screen and (max-width: 800px) {
    display: ${({ mobile }) => (mobile ? "block" : "none")};
  }
  @media screen and (max-width: 420px) {
    margin-top: 50px;
  }
`;

const NewsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  @media screen and (max-width: 1450px) {
    margin-left: -30px;
  }
`;

export default News;
