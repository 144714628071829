import { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { mobHamburgerCSS } from "../atoms/hamburger";
import menuNose2 from "../../assets/images/menu_nose2.png";
import tabletLogo from "../../assets/images/logos/tablet-logo.png";
import mobileLogo from "../../assets/images/logos/mobile-logo.png";
import whiteLogo from "../../assets/images/logos/white-logo.png";
import { pageList as defaultPageList } from "../../helpers/pageList";
import KrEnComponent from '../mobile/molecules/LanguageButton';

const Sidebar = ({ toggleSidebar }) => {
  const ProductSubmenu = () => (
    <SubmenuWrapper>
      <Link to="/mobile/product">
        <Submenu onClick={toggleSidebar}>제품소개</Submenu>
      </Link>
      <Link to="/mobile/software">
        <Submenu onClick={toggleSidebar}>이노핏 소프트웨어</Submenu>
      </Link>
    </SubmenuWrapper>
  );

  const ProductSubmenu1 = () => (
    <SubmenuWrapper>
      <Link to="/mobile/lab">
        <Submenu onClick={toggleSidebar}>이노핏랩 소개</Submenu>
      </Link>
      <Link to="/mobile/event">
        <Submenu onClick={toggleSidebar}>이벤트</Submenu>
      </Link>
    </SubmenuWrapper>
  );

  const pageList = defaultPageList.map((e) =>
    e.name === "Product" ? { ...e, submenu: ProductSubmenu } : e.name === "innofitLab" ? { ...e, submenu: ProductSubmenu1 } :e
  );

  const MainMenuComponent = ({ name, i, onClick = () => {} }) => (
    <MainMenuWrapper onClick={onClick}>
      <Number>0{i + 1}</Number>
      <Menu>{name}</Menu>
    </MainMenuWrapper>
  );

  return (
    <SidebarWrapper>
      <KrEnWrapper>
        <KrEnComponent />
      </KrEnWrapper>
      <Link to="/mobile">
        <Logo src={whiteLogo} onClick={toggleSidebar} />
      </Link>
      <MenuList>
        {pageList.map((p, i) => (
          <MenuWrapper key={p.name}>
            {p.submenu ? (
              <>
                <MainMenuComponent name={p.name} i={i} /> {p.submenu()}
              </>
            ) : (
              <Link to={'/mobile' + p.link}>
                <MainMenuComponent
                  name={p.name}
                  i={i}
                  onClick={toggleSidebar}
                />
              </Link>
            )}
          </MenuWrapper>
        ))}
      </MenuList>
      <Contact>
        Contact us<br/>
        <a href='tel:02-421-1314'>02.421.1314</a> / <a href='mailto:sales@anymedi.com'>sales@anymedi.com</a>
      </Contact>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.div`
  width: 95vw;
  height: 100vh;
  background: #f78b8b;
  background-image: url(${menuNose2});
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-101%);
  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 95px 60px 0px;
  background-position: 130px 170px;
  overflow: auto;
`;
const Logo = styled.img`
  width: 120px;
  height: 24px;
`;
const MenuList = styled.div`
  margin-top: 5px;
  padding: 30px 0px 12px;
  border-top: 1px solid #f4afac;
  border-bottom: 1px solid #f4afac;
`;

const Number = styled.span`
  font-family: "Saira Condensed";
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  -webkit-transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
`;
const Menu = styled.span`
  font-family: "Saira Condensed";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-left: 25px;
  -webkit-transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
`;
const MainMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SubmenuWrapper = styled.div`
  margin-left: 35px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  height: 0px;
  overflow: hidden;
`;
const Submenu = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #ffffff;
  :hover {
    color: #f2bb91;
  }
`;
const MenuWrapper = styled.div`
  color: #fff;
  margin-bottom: 18px;
  :hover {
    ${Number} {
      color: #f2bb91;
    }
    ${Menu} {
      color: #f2bb91;
    }
    ${SubmenuWrapper} {
      padding-top: 10px;
      height: 74px;
      
      a + a ${Submenu} {
        margin-top: 4px;
      }
    }
  }
`;
const Contact = styled.p`
  margin-top: 30px;
  text-align: right;
  font-family: 'Saira Condensed';
  font-size: 14px;
  line-height: 20px;
  color: white;

  a {
    font-family: inherit;
  }
`;
const KrEnWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

const MobileNavComponent = () => {
  const hamburgerWrapper = useRef();
  const toggleSidebar = () => {
    hamburgerWrapper.current.classList.toggle("open");

    const noScroll = hamburgerWrapper.current.classList.contains("open");

    document.body.style.overflow = noScroll ? "hidden" : "unset";
  };

  const Hamburger = () => (
    <div ref={hamburgerWrapper} className="nav-fixed">
      <svg
        className="mo nav-icon-hamburger nav-fixed"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        onClick={toggleSidebar}
      >
        <g
          className="nav-icon-color nav-icon-hamburger-1"
          fill="none"
          fillRule="evenodd"
          strokeWidth="1.6"
          strokeDasharray="16"
          strokeDashoffset="0"
        >
          <path d="M0 .25L15.75.25" transform="translate(4.5 6.75)"></path>
        </g>
        <g
          className="nav-icon-color nav-icon-hamburger-2"
          fill="none"
          fillRule="evenodd"
          strokeWidth="1.6"
          strokeDasharray="16"
          strokeDashoffset="0"
        >
          <path d="M0 5.25L15.75 5.25" transform="translate(4.5 6.75)"></path>
        </g>
        <g
          className="nav-icon-color nav-icon-hamburger-3"
          fill="none"
          fillRule="evenodd"
          strokeWidth="1.6"
          strokeDasharray="16"
          strokeDashoffset="0"
        >
          <path d="M0 10.25L15.75 10.25" transform="translate(4.5 6.75)"></path>
        </g>
        <g
          className="nav-icon-color nav-icon-hamburger-close-1"
          fill="none"
          fillRule="evenodd"
          strokeWidth="2"
          strokeDasharray="20"
          strokeDashoffset="20"
        >
          <path d="M0 0.272L14.4 13.584" transform="translate(4.8 4.8)"></path>
        </g>
        <g
          className="nav-icon-color nav-icon-hamburger-close-2"
          fill="none"
          fillRule="evenodd"
          strokeWidth="2"
          strokeDasharray="20"
          strokeDashoffset="20"
        >
          <path
            d="M0 0.272L14.4 13.584"
            transform="translate(4.8 4.8) matrix(-1 0 0 1 14.4 0)"
          ></path>
        </g>
      </svg>
      <Sidebar toggleSidebar={toggleSidebar} />
      <Backdrop />
    </div>
  );

  return (
    <NavWrapper>
      <HamburgerWrapper>
        <Hamburger />
      </HamburgerWrapper>
      <Link to="/mobile">
        <TabletLogo src={tabletLogo} />
        <MobileLogo src={mobileLogo} />
      </Link>
    </NavWrapper>
  );
};

const NavWrapper = styled.nav`
  height: 70px;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  display: none;
  width: 100%;
  position: fixed;
  z-index: 10;
  @media screen and (max-width: 500px) {
    height: 50px;
  }
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;
const TabletLogo = styled.img`
  cursor: pointer;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
const MobileLogo = styled.img`
  cursor: pointer;
  display: none;
  width: 80px;
  height: 16px;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  @media screen and (max-width: 420px) {
    background: rgba(56, 56, 56, 0.8);
  }
`;
const HamburgerWrapper = styled.div`
  position: absolute;
  left: 15px;
  ${mobHamburgerCSS}
  .nav-fixed.open {
    ${SidebarWrapper} {
      transform: translateX(0);
    }
    ${Backdrop} {
      opacity: 1;
      display: block;
    }
  }
`;

export default MobileNavComponent;
