import styled from "styled-components";
import HashLinkWithOffset from '../../molecules/HashLinkWithOffset';

import { PageWrapper } from "../../atoms";
import { Cubes } from "../../molecules";

import pinkArrow from "../../../../assets/images/icons/pink-arrow2.png";
import product1 from "../../../../assets/images/landing/product1.png";
import product2 from "../../../../assets/images/landing/product2.png";
import product3 from "../../../../assets/images/landing/product3.png";
import productMob1 from "../../../../assets/images/landing/productMob1.png";
import productMob2 from "../../../../assets/images/landing/productMob2.png";
import productMob3 from "../../../../assets/images/landing/productMob3.png";

const ItemList = [
  {
    image: product1,
    imageMob: productMob1,
    number: "01",
    subtitle: "이노핏 코 보형물",
    title: "고형이식의료용실리콘재료",
    content1: "CT영상에서 코의 형태를 분석해 맞춤 제작되는",
    content2: "코 성형 및 치료 목적의 보형물",
    content3: "[sub]* 식약처(MFDS) 품목허가 완료",
    subcontent: [
      "· 품  목  명 : 고형이식의료용실리콘재료",
      "· 사용목적 : 이식형의 단단한 재료로서 환자 맞춤형으로 코의 성형 및\n   치료를 목적으로 인체에 삽입하여 사용함",
    ],
    button: "자세히 보기",
    link: "/mobile/product#innofit",
    className: "",
  },
  {
    image: product2,
    imageMob: productMob2,
    number: "02",
    subtitle: "이노핏 코 성형 가이드",
    title: "의료용 가이드",
    content1: "보형물의 진로, 위치, 수술 부위의 표시 등을",
    content2: "안내하는 수술 보조기구",
    content3: "[sub]* 한국의료기기안전정보원(NIDS) 품목 제조신고 완료",
    subcontent: [
      "· 품  목  명 : 의료용 가이드",
      "· 사용목적 : 임플란트 또는 기구의 진로, 위치, 수술부위의 표시 등을\n   안내하기 위하여 사용하는 기구. 단 침습형일회용 및 치과용은 제외함",
    ],
    button: "자세히 보기",
    link: "/mobile/product#guide",
    className: "",
  },
  {
    image: product3,
    imageMob: productMob3,
    number: "03",
    title: "이노핏랩 APP",
    content1: "이노핏랩은 모바일에서 누구나 가상성형이",
    content2: "가능하도록 앱 서비스로 출시될 예정입니다.",
    content3: "이노핏랩을 모바일에서 만나 보세요!",
    button: "",
    link: "#/",
    className: "",
  },
];

const ProductItem = ({ item }) => (
  <ItemWrapper>
    <HashLinkWithOffset to={item.link}>
      <Left>
        <ItemImage src={item.image} />
        <ItemImageMob src={item.imageMob} />
      </Left>
      <Right>
        <ItemDetail>
          {item.subtitle && (
            <ItemTitle className="upper">{item.subtitle}</ItemTitle>
          )}
          <ItemTitle>
            <Number>{item.number}</Number>
            {item.title}
          </ItemTitle>
          <ItemContent>
            {item.content1}
            {item.content2 && <br />}
            {item.content2}
            {item.content3 && <br />}
            {item.content3.startsWith('[sub]') ? (
              <sub>{item.content3.substr(5)}</sub>
            ) : item.content3}
            {item.subcontent && item.subcontent.map(sub => <sub>{sub}</sub>)}
          </ItemContent>
        </ItemDetail>
        {item.button.length !== 0 && <ButtonWrapper>{item.button}</ButtonWrapper>}
      </Right>
    </HashLinkWithOffset>
  </ItemWrapper>
);

const ItemWrapper = styled.div`
  display: flex;
  & + & {
    margin-top: 50px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  margin-right: 124px;
  @media screen and (max-width: 1450px) {
    margin-right: 40px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;
const ItemImage = styled.img`
  width: 248px;
  height: 170px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const ItemImageMob = styled.img`
  display: none;
  @media screen and (max-width: 800px) {
    display: inline-block;
    width: 100%;
    height: auto;
  }
`;

const Right = styled.div`
  flex: 1;
  padding-left: 40px;
  position: relative;
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  @media screen and (max-width: 420px) {
    border: none;
    padding: 0px;
    margin-top: 24px;
  }
`;
const Number = styled.div`
  color: #ff6969;
  font-weight: bold;
  font-family: "Saira Condensed";
  font-size: 18px;
  line-height: 28px;
  position: absolute;
  left: 0%;
  @media screen and (max-width: 420px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
`;
const ItemDetail = styled.div``;
const ButtonWrapper = styled.div`
  @media screen and (max-width: 420px) {
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: #F78B8B;

    &::after {
      content: "";
      display: inline-block;
      width: 21px;
      height: 8px;
      background-image: url(${pinkArrow});
      background-size: contain;
      margin-left: 10px;
    }
  }
`;
const ItemTitle = styled.p`
  letter-spacing: -0.05em;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  @media screen and (max-width: 420px) {
    padding-left: 25px;
    padding-bottom: 8px;
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.05em;
    color: #000000;
    border-bottom: 1px solid #d8d8d8;
  }

  &.upper {
    border-bottom: none;
    color: #5a5a5a;
    font-family: 'S-Core Dream';
    font-style: normal;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: -0.05em;
    padding-bottom: 0px;
    margin-bottom: -5px;
    padding-left: 0px;
  }
`;
const ItemContent = styled.p`
  letter-spacing: -0.05em;
  color: #5a5a5a;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-top: 20px;

  sub {
    font-size: 16px;
  }

  @media screen and (max-width: 1100px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 800px) {
    br {
      display: none;
    }
  }
  @media screen and (max-width: 420px) {
    font-family: 'S-Core Dream';
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.05em;
    color: #5A5A5A;
    margin-top: 10px;
    margin-bottom: 16px;

    br {
      display: unset;
    }

    sub {
      &:nth-of-type(1) {
        font-size: 13px;
        line-height: 20px;
        margin-top: 6px;
      }

      display: block;
      font-family: 'S-Core Dream';
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: -0.05em;
      color: #989898;
      white-space: pre-wrap;
    }
  }
`;

const ProductComponent = () => (
  <Product>
    <PageWrapper>
      <ProductWrapper>
        <Top>
          <TitleWrapper>
            <SectionTitle>OUR PRODUCT</SectionTitle>
            <SectionSubTitle>
              이노핏은 맞춤형 솔루션을 제공합니다.
            </SectionSubTitle>
          </TitleWrapper>
        </Top>
        <Cubes />
        <ProductItemWrapper>
          {ItemList.map((item) => (
            <ProductItem item={item} key={item.number} />
          ))}
        </ProductItemWrapper>
      </ProductWrapper>
    </PageWrapper>
  </Product>
);

const Product = styled.div`
  position: relative;
  border-radius: 0px 180px 0px 0px;
`;
const ProductWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;
const TitleWrapper = styled.div`
  text-align: right;
  margin-left: auto;
  @media screen and (max-width: 800px) {
    margin-bottom: 70px;
  }
  @media screen and (max-width: 420px) {
    text-align: unset;
    margin-left: unset;
    margin-bottom: 50px;
  }
`;
const SectionTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
  @media screen and (max-width: 420px) {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;
const SectionSubTitle = styled.p`
  letter-spacing: -0.05em;
  font-size: 15px;
  line-height: 26px;
  font-weight: 300;
  margin-top: 20px;
  br {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
  @media screen and (max-width: 420px) {
    margin-top: 10px;
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: #383838;
  }
`;
const ProductItemWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 40px;
    margin-bottom: 100px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 14px;
    margin-bottom: 80px;
  }
`;

export default ProductComponent;
