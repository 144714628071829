import React from "react";
import classNames from "classnames/bind";
import { Helmet } from "react-helmet-async";

import logo from "../../assets/images/logo.png";

import styles from "../../styles/Welcome.module.scss";

const cx = classNames.bind(styles);

const url = document.URL;
const token = new URL(url).searchParams.get("token");

const ThankYou = () => {
  if (!token) {
    window.location.href = "/";
  }
  return (
    <div className={cx("body")}>
      <Helmet>
        <title>가입완료 | innofit</title>
      </Helmet>
      <main className={cx("main")}>
        <img src={logo} alt="logo" className={cx("logo")} />
        <p className={cx("title")}>회원가입을 축하드립니다.</p>
        <div className={cx("wrapper")}>
          이노핏 회원 가입을 감사드립니다.
          <br />
          이제, 이노핏의 가상성형 소프트웨어로
          <br />
          원하는 코를 직접 디자인해 보세요.
        </div>
        <a
          href="https://innofit-software.s3.ap-northeast-2.amazonaws.com/innofit_setup.exe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={cx("btn-download")}>
            <span>소프트웨어 설치하기</span>
          </button>
        </a>
        <p className={cx("copyright")}>
          Copyrightⓒ Anymedi Inc. All Rights Reserved
        </p>
      </main>
    </div>
  );
};

export default ThankYou;
