import styled from "styled-components";
import { PageWrapper } from "../../atoms";
import { Chapter, Cubes } from "../../molecules";
import { PinkButton } from "../../molecules/buttons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import info1 from "../../../assets/images/landing/info1.png";
import info2 from "../../../assets/images/landing/info2.png";
import info3 from "../../../assets/images/landing/info3.png";
import info4 from "../../../assets/images/landing/info4.png";

const infoList = [
  {
    image: info1,
    number: "info 01",
    name: "뉴스",
    button: "뉴스 보러가기",
    link: "/pr#news",
  },
  {
    image: info2,
    number: "info 02",
    name: "본사소개",
    button: "회사정보 보러가기",
    link: "/pr",
  },
  {
    image: info3,
    number: "info 03",
    name: "협력병원",
    button: "협력병원 보러가기",
    link: "/membership",
  },
  {
    image: info4,
    number: "info 04",
    name: "찾아 오는 길",
    button: "이노핏 오는 길",
    link: "/pr#map",
  },
];

const Info = ({ info }) => (
  <InfoWrapper>
    <InfoLeft>
      <Circle>
        <Icon src={info.image} />
      </Circle>
    </InfoLeft>
    <InfoRight>
      <Number>{info.number}</Number>
      <Name>{info.name}</Name>
      <HashLink to={info.link}>
        <LinkButton className={info.className}>{info.button}</LinkButton>
      </HashLink>
    </InfoRight>
  </InfoWrapper>
);

const InfoWrapper = styled.div`
  display: flex;
  width: 332px;
  margin-right: 100px;
  margin-bottom: 70px;
  @media screen and (max-width: 900px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0px;
    margin-bottom: 30px;
  }
`;
const InfoLeft = styled.div`
  margin-right: 40px;
`;
const Circle = styled.div`
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  @media screen and (max-width: 800px) {
    width: 120px;
    height: 120px;
  }
`;
const Icon = styled.img`
  width: 94px;
  height: 94px;
`;
const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Number = styled.span`
  color: #ff6969;
  font-family: "Saira Condensed";
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;
const Name = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  margin-top: 13px;
`;
const LinkButton = styled.button`
  margin-top: 29px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #c4c4c4;
  width: 132px;
  border-top: 1px solid #c4c4c4;
  height: 26px;
  display: flex;
  padding: 0;
  padding-top: 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: visible;
  transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  :hover {
    color: #5a5a5a;
    :before {
      width: 100%;
    }
  }

  :before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    right: auto;
    z-index: 1;
    background-color: #5a5a5a;
    width: 0%;
    height: 1px;
    -webkit-transition: width 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: width 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`;

const InformationComponent = () => (
  <Information>
    <GreyBackground>
      <TopGrey />
      <TopWhite />
    </GreyBackground>
    <PageWrapper>
      <InformationWrapper>
        <SectionTitle>
          INFORMATION
          <Chapter name="CHAPTER 04" />
        </SectionTitle>
        <SectionSubtitle>신뢰를 바탕으로 함께 합니다.</SectionSubtitle>

        <Main>
          <Left>
            <Chapter name="CHAPTER 04" />
            <ChpaterCubeGap />
            <Cubes />
          </Left>
        </Main>
        <InfoList>
          {infoList.map((info) => (
            <Info info={info} key={info.name} />
          ))}
        </InfoList>
        <Link to="/pr">
          <PinkButton name="PR 바로가기" width="212" />
        </Link>
      </InformationWrapper>
    </PageWrapper>
  </Information>
);

const Information = styled.div`
  padding-top: 160px;
  position: relative;
  padding-bottom: 140px;
  @media screen and (max-width: 1450px) {
    padding-top: 200px;
  }
  @media screen and (max-width: 800px) {
    padding-bottom: 100px;
  }
`;
const GreyBackground = styled.div`
  background: #f3f3f3;
  border-radius: 180px 0px 0px 0px;
  position: absolute;
  height: 100%;
  width: 68vw;
  top: 0;
  right: 0;
  z-index: -1;
  @media screen and (max-width: 1100px) {
    width: 70vw;
  }
  @media screen and (max-width: 800px) {
    width: calc(100vw - 36px);
    height: 1600px;
    border-radius: 100px 0px 0px 0px;
    top: 90px;
  }
`;
const TopGrey = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  top: -180px;
  right: 0px;
  background-color: #f3f3f3;
`;
const TopWhite = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  top: -180px;
  right: 0px;
  background-color: white;
  border-bottom-right-radius: 180px;

  @media screen and (max-width: 800px) {
    border-bottom-right-radius: 100px;
  }
`;
const InformationWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
`;

const SectionTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
  position: relative;

  > div {
    position: absolute;
    top: -4px;
    left: 360px;
    font-weight: 400;
  }

  @media screen and (max-width: 800px) {
    > div {
      display: none;
    }
  }
`;
const SectionSubtitle = styled.p`
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  margin-top: 20px;
`;

const Main = styled.div`
  display: flex;
  margin-top: 60px;
  margin-left: 220px;

  @media screen and (max-width: 1450px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;

  > div:first-of-type {
    display: none;
  }

  @media screen and (max-width: 800px) {
    align-items: flex-start;

    > div:first-of-type {
      display: flex;
    }
  }
`;
const ChpaterCubeGap = styled.div`
  display: none;
  height: 40px;
  width: 1;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

const InfoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 220px;
  @media screen and (max-width: 1450px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 50px;
  }
`;

export default InformationComponent;
