import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";

import { pageList as defaultPageList } from "../../helpers/pageList";
import { PageWrapper } from "../atoms";
import logo from "../../assets/images/logos/logo.png";
import { LanguageButton } from "../molecules";

import whiteArrow from "../../assets/images/icons/hover-menu-white-arrow.png";
import greyArrow from "../../assets/images/icons/hover-menu-grey-arrow.png";

const Nav = ({ location }) => {
  const { pathname } = location;
  const ProductHoverMenu = () => (
    <HoverMenuWrapper>
      <HoverMenuContainer>
        <Link to="/product">
          <HoverMenu>제품소개</HoverMenu>
        </Link>
        <Link to="/software">
          <HoverMenu>이노핏 소프트웨어</HoverMenu>
        </Link>
      </HoverMenuContainer>
    </HoverMenuWrapper>
  );

  const ProductHoverMenu1 = () => (
    <HoverMenuWrapper>
      <HoverMenuContainer>
        <Link to="/lab">
          <HoverMenu>이노핏랩 소개</HoverMenu>
        </Link>
        <Link to="/event">
          <HoverMenu>이벤트</HoverMenu>
        </Link>
      </HoverMenuContainer>
    </HoverMenuWrapper>
  );

  // const pageList = defaultPageList.map((e) =>
  //   e.name === "Product" ? { ...e, hoverMenu: ProductHoverMenu } :   e
  // );

  const pageList = defaultPageList.map((e) =>
  e.name === "Product" ? { ...e, hoverMenu: ProductHoverMenu } :   e.name === "innofitLab" ? { ...e, hoverMenu: ProductHoverMenu1 } :e
  );

  const Component = () => (
    <NavWrapper pathname={pathname}>
      <PageWrapper>
        <NavMain>
          <Link to="/">
            <Logo src={logo} />
          </Link>
          <MenuWrapper>
            {pageList.map((menu) => (
              <Menu
                key={menu.name}
                active={
                  pathname === menu.link ||
                  (pathname === "/software" && menu.link === "/product")
                }
              >
                <Link to={menu.link}>{menu.name}</Link>
                {menu.hoverMenu && menu.hoverMenu()}
              </Menu>
            ))}
          </MenuWrapper>
          <LanguageButton />
        </NavMain>
      </PageWrapper>
    </NavWrapper>
  );
  return <Component />
};

const NavWrapper = styled.nav`
  padding: 25px 0;
  position: ${({ pathname }) => (pathname === "/" ? "absolute" : "fixed")};
  background: ${({ pathname }) => pathname !== "/" && "#fff"};
  box-shadow: ${({ pathname }) =>
    pathname !== "/" && "0px 3px 10px rgba(0, 0, 0, 0.1)"};
  width: 100%;
  z-index: 3;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const NavMain = styled.div`
  display: flex;
`;
const Logo = styled.img`
  width: 114px;
`;

const HoverMenuWrapper = styled.div`
  display: none;
  position: absolute;
  padding-top: 25px;
  left: 0px;
`;
const HoverMenu = styled.div`
  color: #828282;
  font-weight: 400;
  font-size: 13px;
  line-height: 44px;
  padding-left: 14px;
  height: 44px;
  cursor: pointer;
  letter-spacing: -0.05em;
  position: relative;
  box-sizing: content-box;
  background-color: white;

  :hover {
    color: white;
    background-color: #ff6969;
  }

  :after {
    content: "";
    background-image: url(${greyArrow});
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    right: 13px;
    top: 18px;
  }

  :hover:after {
    background-image: url(${whiteArrow});
  }
`;
const HoverMenuContainer = styled.div`
  border: 1px solid #f4afac;
  width: 150px;
  position: relative;
  border-bottom-right-radius: 16px;
  overflow: hidden;

  a:last-of-type ${HoverMenu} {
    border-top: 1px solid #f4afac;
  }
`;

const MenuWrapper = styled.div`
  margin: 0 60px;
  display: flex;
  align-items: center;
`;
const Menu = styled.div`
  font-size: 20px;
  line-height: 25px;
  color: ${({ active }) => (active ? "#ff6969" : "#5a5a5a")};
  transition: color 0.3s;
  position: relative;
  z-index: 1;

  :hover {
    ${HoverMenuWrapper} {
      display: block;
    }
  }

  & + & {
    margin-left: 40px;
    @media screen and (max-width: 1100px) {
      margin-left: 30px;
    }
  }

  > a {
    font-family: "Saira Condensed";

    :before {
      display: block;
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #989898;
      content: "";
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1);
      transform: scaleX(0);
      transform-origin: right center;
    }

    :hover:before {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
`;

export default withRouter(Nav);
