import styled from "styled-components";
import { Cubes } from "../molecules";
import header1 from "../../assets/images/story/header1.jpg";
import header2 from "../../assets/images/story/header2.jpg";
import header3 from "../../assets/images/story/header3.png";
import header4 from "../../assets/images/story/header4.png";
import second from "../../assets/images/story/second.png";
import second2 from "../../assets/images/story/second2.png";
import secondMob from "../../assets/images/story/secondMob.png";
import third from "../../assets/images/story/third.png";
import thirdMob from "../../assets/images/story/thirdMob.png";

import { PageWrapper } from "../atoms";

const StoryComponent = () => (
  <Story>
    <First>
      <FirstBackground>
        <FirstBackgroundText>BRAND STORY</FirstBackgroundText>
      </FirstBackground>
      <PageWrapper>
        <FirstWrapper>
          <FirstLeft>
            <Cubes />
            <SectionTitle>
              당신의 삶에 알맞게 FIT,&nbsp;
              <BR2 />
              <BR1 />
              상상을 현실로 만들고자 <BR2 />
              합니다.
            </SectionTitle>
            <FirstContent>
              자연스러운 아름다움을 추구하는 이노핏은,
              <br />
              단순히 외적인 아름다움만을 중시하지 않습니다.
              <br />
              이노핏은 개개인에게 맞는 아름다움을 찾고, 이를 구현합니다.
              <br />
              &nbsp;
            </FirstContent>
          </FirstLeft>
          <FirstBackgroundTextMob>BRAND STORY</FirstBackgroundTextMob>
          <Header3 src={header3} />
          <Header4 src={header4} />
        </FirstWrapper>
      </PageWrapper>
    </First>
    <Second>
      <GreyBackground />
      <PageWrapper>
        <SecondWrapper>
          <SecondLeft>
            <SecondImage src={second} />
            <SecondImage2 src={second2} />
            <SecondImageMob src={secondMob} />
          </SecondLeft>
          <SecondRight>
            <Cubes />
            <SectionTitle>기술에 기술을 더했습니다</SectionTitle>
            <SecondContent>
              이노핏은 맞춤형 수술 솔루션 전문 기업, <br/>
              애니메디솔루션(주)의 프리미엄 보형물입니다. <br/>
              인공지능 기술과 3D 프린팅을 적용해 만들어지는 이노핏은, <br/>
              기술을 통해 자연스러운 아름다움을 극대화하고, <br/>
              개인이 가진 고유한 FIT을 돋보이게 합니다.
            </SecondContent>
          </SecondRight>
        </SecondWrapper>
      </PageWrapper>
    </Second>
    <Third>
      <PinkBackground />
      <PageWrapper>
        <ThirdWrapper>
          <ThirdLeft>
            <Cubes />
            <SectionTitle>
              나를 가장 나답게
              <br />
              innofit 하다
            </SectionTitle>
          </ThirdLeft>
          <ThirdRight>
            <ThirdContent>
              이노핏은 프리미엄 보형물입니다. <br />
              앞선 기술력과 축적된 노하우를 바탕으로 <br />
              한 사람만을 위한 맞춤형 보형물을 제공합니다.
            </ThirdContent>
            <ThirdImageWrapper>
              <ThirdImage src={third} />
              <ThirdImageMob src={thirdMob} />
            </ThirdImageWrapper>
          </ThirdRight>
        </ThirdWrapper>
      </PageWrapper>
    </Third>
  </Story>
);

const Story = styled.div`
  position: relative;
`;
const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  margin-top: 16px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 42px;
  }
`;

const First = styled.div`
  position: relative;
`;
const FirstBackground = styled.div`
  right: 0;
  height: 460px;
  width: calc(50vw - 0px);
  position: absolute;
  background: #ff9391;
  background-image: url(${header1});
  background-position: right;
  background-size: cover;
  border-radius: 0px 0px 0px 180px;
  @media screen and (max-width: 1100px) {
    background-image: url(${header2});
    background-position: left;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const FirstBackgroundText = styled.p`
  position: relative;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 120px;
  line-height: 130px;
  color: #f3f3f3;
  right: 0;
  top: 435px;
  z-index: -1;
  @media screen and (max-width: 1200px) {
    font-size: 100px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 80px;
  }
`;
const FirstBackgroundTextMob = styled.p`
  font-family: "Saira Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  color: #f3f3f3;
  display: none;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
  @media screen and (max-width: 660px) {
    font-size: 88px;
    line-height: 80px;
  }
`;

const Header3 = styled.img`
  display: none;
  margin-right: -20px;
  width: calc(100% + 20px);
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
const Header4 = styled.img`
  display: none;
  margin-right: -20px;
  width: calc(100% + 20px);
  @media screen and (max-width: 500px) {
    display: inline-block;
  }
`;
const FirstWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  align-items: flex-start;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;
const FirstLeft = styled.div`
  padding-top: 140px;
  @media screen and (max-width: 800px) {
    padding-top: 40px;
  }
`;
const FirstContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  width: 495px;
  margin-top: 30px;
  @media screen and (max-width: 1100px) {
    width: 328px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const BR1 = styled.br`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
const BR2 = styled.br`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Second = styled.div`
  position: relative;
  margin-top: 350px;
  @media screen and (max-width: 800px) {
    margin-top: 200px;
  }
`;
const SecondWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const SecondLeft = styled.div`
  margin-right: 40px;
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;
const SecondImage = styled.img`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
const SecondImage2 = styled.img`
  display: none;
  width: 330px;
  height: auto;
  @media screen and (max-width: 1100px) {
    display: inline-block;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const SecondImageMob = styled.img`
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;
const SecondRight = styled.div`
  @media screen and (max-width: 800px) {
    margin-top: 60px;
  }
`;
const SecondContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  width: 495px;
  margin-top: 30px;
  @media screen and (max-width: 1100px) {
    width: unset;

    br{
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const GreyBackground = styled.div`
  background: #f3f3f3;
  height: 682px;
  width: 85vw;
  border-radius: 0px 180px 0px 0px;
  position: absolute;
  left: 0;
  top: -130px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    top: -100px;
    width: 95vw;
    height: 1110px;
    border-radius: 0px 100px 0px 0px;
  }
`;

const Third = styled.div`
  position: relative;
  margin-top: 180px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
`;
const ThirdWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 140px;
  @media screen and (max-width: 800px) {
    margin-bottom: 100px;
    flex-direction: column;
  }
`;
const ThirdLeft = styled.div`
  margin-top: 130px;
  width: 332px;
  margin-right: 90px;
  @media screen and (max-width: 1100px) {
    width: 194px;
  }
  @media screen and (max-width: 900px) {
    width: calc(35vw - 45px);
    margin-right: 0px;
  }
  @media screen and (max-width: 800px) {
    margin: 0;
    width: unset;
  }
`;
const ThirdRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: -20px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  @media screen and (max-width: 800px) {
    margin-left: 40px;
    padding-right: 20px;
  }
`;
const ThirdContent = styled.p`
  min-width: 378px;
  max-width: 390px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #f3f3f3;
  padding: 63px 0px 0px 40px;
  @media screen and (max-width: 1100px) {
    padding: unset;
  }
  @media screen and (max-width: 800px) {
    max-width: 720px;
    font-size: 16px;
    line-height: 28px;
    margin-top: 74px;
    br {
      display: none;
    }
  }
`;
const PinkBackground = styled.div`
  position: absolute;
  right: 0;
  top: -35px;
  background: #f78b8b;
  border-radius: 40px 0px 0px 40px;
  height: 300px;
  width: calc(50vw + 200px);
  z-index: -1;
  @media screen and (max-width: 1450px) {
    width: calc(100vw - 470px);
  }
  @media screen and (max-width: 1100px) {
    width: calc(100vw - 330px);
    height: 437px;
  }
  @media screen and (max-width: 900px) {
    width: 65vw;
  }
  @media screen and (max-width: 800px) {
    width: 95vw;
    top: 155px;
    border-radius: 40px 0px 0px 40px;
  }
`;
const ThirdImageWrapper = styled.div`
  @media screen and (max-width: 1100px) {
    margin-top: 20px;
  }
`;
const ThirdImage = styled.img`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const ThirdImageMob = styled.img`
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;
export default StoryComponent;
