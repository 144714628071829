import styled, { css } from "styled-components";
import pinkArrow from "../../../assets/images/icons/pink-arrow2.png";
import whiteArrow from "../../../assets/images/icons/white-arrow2.png";

export const PinkButton = ({ name, width, padding, arrow = true, className }) => (
  <PinkBtn width={width} padding={padding} className={className}>
    <span className={className}>{name}</span>
    {arrow && (
      <>
        <PinkArrow className={className} src={pinkArrow} />
        <WhiteArrow className={className} src={whiteArrow} />
      </>
    )}
  </PinkBtn>
);

export const BeigeButton = ({ name, className }) => (
  <BeigeBtn className={className}>
    <span className={className}>{name}</span>
    <WhiteArrow src={whiteArrow} className={className} />
  </BeigeBtn>
);

export const WhiteButton = ({ name, className }) => (
  <WhiteBtn className={className}>
    <span>{name}</span>
    <WhiteArrow src={whiteArrow} />
    <PinkArrow src={pinkArrow} />
  </WhiteBtn>
);

const Arrow = styled.img`
  position: absolute;
  top: 21px;
  right: 15px;
  z-index: 2;
  transition: all 500ms;
  width: 21px;
  height: 8px;
`;

const PinkArrow = styled(Arrow)``;
const WhiteArrow = styled(Arrow)``;

const ButtonAnimation = css`
  :before {
    content: "";
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    right: auto;
    bottom: auto;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: left 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: left 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`;
const btn = styled.button`
  font-weight: 500;
  font-size: 18px;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  width: ${({ width }) => (width ? width + "px" : "max-content")};
  transition: color 500ms;
  overflow: hidden;
  span {
    position: relative;
  }
  ${ButtonAnimation}
  @media screen and (max-width: 420px) {
    font-size: 16px;
    letter-spacing: -0.05em;
    padding: 0px;
    width: 220px;
  }
`;

const PinkBtn = styled(btn)`
  border: 1px solid #ff6969;
  color: #ff6969;
  ${WhiteArrow} {
    opacity: 0;
  }
  :hover {
    color: #fff;
    ${WhiteArrow} {
      opacity: 1;
    }
    ${PinkArrow} {
      opacity: 0;
    }
    :before {
      left: 0%;
    }
  }
  :before {
    background: #ff6969;
  }
`;

const BeigeBtn = styled(btn)`
  background: #f2bb91;
  color: #fff;
  :hover {
    :before {
      left: 0%;
    }
  }
  :before {
    background: #ea9d62;
  }
`;

const WhiteBtn = styled(btn)`
  border: 1px solid #fff;
  color: #fff;
  ${PinkArrow} {
    opacity: 0;
  }
  :hover {
    color: #ff6969;
    :before {
      left: 0%;
    }
    ${PinkArrow} {
      opacity: 1;
    }
    ${WhiteArrow} {
      opacity: 0;
    }
  }
  :before {
    background: #fff;
  }
`;
