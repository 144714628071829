import styled from "styled-components";
const KrEnComponent = () => {
  return (
    <Wrapper>
      <KrButton>KR</KrButton>
      <EnButton>EN</EnButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const LanguageButton = styled.button`
  color: #fff;
  font-family: "Saira Condensed";
  font-style: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 30px;
`;
const KrButton = styled(LanguageButton)`
  background: #ff6969;
  font-weight: bold;
`;
const EnButton = styled(LanguageButton)`
  background: #f2bb91;
  font-weight: 300;
`;

export default KrEnComponent;
