import styled from "styled-components";
import { Link } from "react-router-dom";

import { PageWrapper } from "../../atoms";
import { Cubes } from "../../molecules";
import { WhiteButton } from "../../molecules/buttons";

import background from "../../../assets/images/landing/software.png";

const SoftwareComponent = () => (
  <Software>
    <PageWrapper>
      <SoftwareWrapper>
        <SectionTitle>SOFTWARE</SectionTitle>
        <SectionSubtitle>기술에 상상을 더합니다.</SectionSubtitle>
        <Main>
          <Cubes />
          <Content>
            이노핏 가상성형 소프트웨어는 3D 페이스 스캐닝을 통해 내 얼굴에 어울리는
            <br />
            모양과 라인을 직접 디자인해 볼 수 있는 3D 가상성형 소프트웨어입니다.
          </Content>
        </Main>
        <Link to="/software">
          <WhiteButton name="소프트웨어 다운로드" />
        </Link>
      </SoftwareWrapper>
    </PageWrapper>
  </Software>
);

const Software = styled.div`
  padding: 140px 0;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 800px) {
    padding: 100px 0;
  }
`;

const SoftwareWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
`;

const SectionTitle = styled.p`
  color: #fff;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
`;
const SectionSubtitle = styled.p`
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  margin-top: 20px;
`;

const Main = styled.div`
  margin-top: 50px;
  margin-bottom: 76px;
  @media screen and (max-width: 800px) {
    margin-bottom: 40px;
  }
`;
const Content = styled.p`
  font-weight: 200;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #f3f3f3;
  margin-top: 16px;
`;

export default SoftwareComponent;
