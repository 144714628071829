import styled from "styled-components";
import { Link } from "react-router-dom";
import { PinkButton } from "../molecules/buttons";
import back from "../../assets/images/product_silicone.png";

const Page404Component = () => (
  <Page404>
    <Container>
      <PageTitle>404 ERROR</PageTitle>
      <H1>죄송합니다. 현재 페이지를 찾을 수 없습니다.</H1>
      <H2>
        존재하지 않는 주소를 입력하셨거나,
        <br />
        요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
      </H2>
      <Link to="/">
        <PinkButton name="메인으로 가기" />
      </Link>
    </Container>
  </Page404>
);

const Page404 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 65px);
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${back});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 44px;
  padding-bottom: 33px;
`;

const PageTitle = styled.div`
  width: 185px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ff6969;
  border-radius: 20px;
`;
const H1 = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  margin-top: 30px;
`;
const H2 = styled.p`
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 10px;
  margin-bottom: 50px;
`;
export default Page404Component;
