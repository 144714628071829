// import Popups from '../templates/Popups';
import {
  Header,
  Brand,
  Product,
  // Video,
  Information,
  Software,
} from "../templates/landing";
const LandingPage = () => {
  return (
    <>
      <Header />
      <Brand />
      <Product />
      {/* <Video /> */}
      <Information />
      <Software />
      {/* <Popups /> */}
    </>
  );
};

export default LandingPage;
