import { useState } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Backdrop, PageWrapper } from "../atoms";
import { KmdiaNumber, ModalTerm } from "../organisms";
import terms from "../../helpers/terms";

import { pageList } from "../../helpers/pageList";

import logo from "../../assets/images/logos/footer-logo.png";
import iconBlog from "../../assets/images/icons/icon-blog.png";
import iconYoutube from "../../assets/images/icons/icon-youtube.png";
import iconInsta from "../../assets/images/icons/icon-insta.png";
import iconFb from "../../assets/images/icons/icon-fb.png";

const FooterComponent = ({location}) => {
  const {pathname} = location;
  const [toggleModal, setToggleModal] = useState(false);
  const [nowTerm, setNowTerm] = useState("");

  const hideModals = () => {
    setToggleModal(false);
    document.body.style.overflow = "unset";
  };
  const showModal = (type) => {
    setNowTerm(terms[type]);
    setToggleModal(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      {toggleModal && <Backdrop onClick={hideModals} />}
      {toggleModal && <ModalTerm term={nowTerm} />}
      <Footer>
        <PageWrapper>
          {["/","/product","/mobile","/mobile/product"].includes(pathname) && <KmdiaNumber />}
          <Top>
            <Left>
              <Logo src={logo} />
              <ModalSpan onClick={() => showModal("personal")}>
                개인정보처리방침
              </ModalSpan>
              <FontWeight200>｜</FontWeight200>
              <ModalSpan onClick={() => showModal("manual")}>
                사용 방법 및 주의사항
              </ModalSpan>
              <FontWeight200>｜</FontWeight200>
              <ModalSpan onClick={() => showModal("email")}>
                이메일 주소 수집거부
              </ModalSpan>
            </Left>
            <Right>
              <div>
                <a
                  href="https://blog.naver.com/innofit_nose"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SNSLink src={iconBlog} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCHkPsqN-Sde1dw_WScBeW2Q"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SNSLink src={iconYoutube} />
                </a>
                <a
                  href="https://www.instagram.com/innofit_nose/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SNSLink src={iconInsta} />
                </a>
                <a
                  href="https://www.facebook.com/Anymedi.Inc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SNSLink src={iconFb} />
                </a>
              </div>
            </Right>
          </Top>
          <HR />
          <Bottom>
            <Left>
              <Info>
                애니메디솔루션(주)<FontWeight200> ｜ </FontWeight200>대표 : 김국배
                {pathname.startsWith("/mobile") ? <BrMob /> : <FontWeight200> ｜ </FontWeight200>}
                사업자등록번호 :
                876-88-00652<FontWeight200> ｜ </FontWeight200>T. 02-421-1314
                <br />
                주소 : 서울 송파구 올림픽로 35길 137, 18층
                1호(한국광고문화회관)
                <br />
                Copyrightⓒ Anymedi Inc. All Rights Reserved
              </Info>
            </Left>
            <Right>
              <Contents>
                <ContentsTitle>Contents</ContentsTitle>
                <ContentsWrapper>
                  {pageList.map((c) => (
                    <Link to={c.link} key={c.name}>
                      <Content>{c.name}</Content>
                    </Link>
                  ))}
                </ContentsWrapper>
              </Contents>
            </Right>
          </Bottom>
        </PageWrapper>
      </Footer>
    </>
  );
};

const Footer = styled.footer`
  padding: 80px 0;
  color: #d8d8d8;
  background: #989898;
  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 420px) {
    padding: 60px 0;
  }
`;
const Right = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  a + a {
    margin-left: 26px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 20px;
  }
`;
const Logo = styled.img`
  margin-right: 42px;
  width: 107px;
  height: 21px;
  position: relative;
  top: 4px;
`;
const Top = styled.div`
  position: relative;
  ${Right} {
    @media screen and (max-width: 880px) {
      position: unset;
      margin-top: 20px;
    }
    @media screen and (max-width: 420px) {
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 800px) {
    ${Logo} {
      display: block;
      margin-bottom: 20px;
    }
  }
`;
const Bottom = styled.div`
  position: relative;
  ${Right} {
    @media screen and (max-width: 1020px) {
      position: unset;
    }
  }
`;
const Left = styled.div`
  margin-right: 75px;
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;
const ModalSpan = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: -0.05em;
  & + & {
  }
  @media screen and (max-width: 420px) {
    display: inline-block;
    line-height: 26px;
  }
`;
const HR = styled.div`
  margin: 20px 0;
  background: #d8d8d8;
  height: 1px;
  @media screen and (max-width: 420px) {
    margin: 30px 0px;
  }
`;
const Info = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.05em;
  @media screen and (max-width: 420px) {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
  }
`;
const FontWeight200 = styled.span`
  font-weight: 200;
`;
const BrMob = styled.br`
  display: none;
  @media screen and (max-width: 420px) {
    display: unset;
  }
`;

const SNSLink = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const Contents = styled.div`
  font-family: "Saira Condensed";
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  a + a {
    margin-left: 10px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 30px;
  }
`;
const ContentsTitle = styled.p`
  margin-right: 20px;
  font-family: "Saira Condensed";
  font-weight: 600;
  margin-top: 10px;
`;
const Content = styled.div`
  font-family: "Saira Condensed";
  cursor: pointer;
  @media screen and (max-width: 420px) {
    font-size: 15px;
  }
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export default withRouter(FooterComponent);
