import { useLocation } from 'react-router-dom';
import styled from 'styled-components'

const KmdiaNumber = () => {
  const {pathname} = useLocation();

  return (
    <>
      {['/', '/mobile'].includes(pathname) && (
        <Wrapper>
          <First>
            이 제품은 의료기기이며, '사용상의 주의사항'과 '사용방법'을 잘 읽고 사용하십시오. (이노핏랩 APP, 3D 가상성형 소프트웨어는 의료기기가 아님)
          </First>
          <Second>
            <Left>광고심의필</Left>
            <Right>
              <span>[고형이식의료용실리콘재료]&nbsp;</span><span>심의번호 22022-I10-33-2004 (유효기간 25.09.07)</span><br/>
              <span>[의료용가이드]&nbsp;</span><span>심의번호 22022-I10-33-2005 (유효기간 25.09.07)</span>
            </Right>
          </Second>
        </Wrapper>
      )}

      {['/product', '/mobile/product'].includes(pathname) && (
        <Wrapper>
          <First>
            이 제품은 의료기기이며, '사용상의 주의사항'과 '사용방법'을 잘 읽고 사용하십시오.
          </First>
          <Second>
            <Left>광고심의필</Left>
            <Right>
              <span>[고형이식의료용실리콘재료]&nbsp;</span><span>심의번호 22022-I10-33-1993 (유효기간 25.09.02)</span><br/>
              <span>[의료용가이드]&nbsp;</span><span>심의번호 22022-I10-33-1994 (유효기간 25.09.02)</span>
            </Right>
          </Second>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 200;
  line-height: 20px;
  color: #d8d8d8;
  margin-bottom: 30px;

  @media screen and (max-width: 540px) {
    font-size: 11px;
    line-height: 18px;
  }

  @media screen and (max-width: 420px) {
    margin-bottom: 20px;
  }
`;
const First = styled.div`
  word-break: keep-all;
`;
const Second = styled.div`
  display: flex;
  gap: 8px;
`;
const Left = styled.div`
  min-width: fit-content;
`;
const Right = styled.div`
  span {
    display: inline-block;
  }

  @media screen and (max-width: 500px) {
    span {
      display: block;
    }
    
    br {
      display: none;
    }
  }
`;

export default KmdiaNumber