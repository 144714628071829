import Routes from "./Routes";

import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <HelmetProvider>
      <Routes />
    </HelmetProvider>
  );
};

export default App;
