import styled from "styled-components";
import { PageWrapper } from "../../atoms";
import { Cubes } from "../../molecules";
import { PinkButton } from "../../molecules/buttons";
import { Link } from "react-router-dom";
import HashLinkWithOffset from '../../molecules/HashLinkWithOffset';

import info1 from "../../../../assets/images/landing/info1.png";
import info2 from "../../../../assets/images/landing/info2.png";
import info3 from "../../../../assets/images/landing/info3.png";
import info4 from "../../../../assets/images/landing/info4.png";
import infoArrow from "../../../../assets/images/landing/infoArrow.png";

const infoList = [
  {
    image: info1,
    number: "info 01",
    name: "뉴스",
    button: "이노핏소식",
    link: "/mobile/pr#news",
  },
  {
    image: info2,
    number: "info 02",
    name: "회사소개",
    button: "본사소개",
    link: "/mobile/pr",
  },
  {
    image: info3,
    number: "info 03",
    name: "협력병원",
    button: "협력병원",
    link: "/mobile/membership",
  },
  {
    image: info4,
    number: "info 04",
    name: "찾아 오는 길",
    button: "찾아오는길",
    link: "/mobile/pr#map",
  },
];

const Info = ({ info }) => (
  <InfoWrapper>
    <InfoLeft>
      <Circle>
        <Icon src={info.image} />
      </Circle>
      <HashLinkWithOffset
        to={info.link}
        offset={info.link === "/mobile/pr#map" ? 200 : undefined}
      >
        {info.button}
      </HashLinkWithOffset>
    </InfoLeft>
    <InfoRight>
      <Number>{info.number}</Number>
      <Name>{info.name}</Name>
      <HashLinkWithOffset
        to={info.link}
        offset={info.link === "/mobile/pr#map" ? 200 : undefined}
      >
        <LinkButton className={info.className}>{info.button}</LinkButton>
      </HashLinkWithOffset>
    </InfoRight>
  </InfoWrapper>
);

const InfoWrapper = styled.div`
  display: flex;
  width: 332px;
  margin-top: 70px;
  margin-left: 100px;
  @media screen and (max-width: 900px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 420px) {
    width: calc(50% - 4.5px);
    margin: 0px;
    background: #F7F7F7;
    border-top: 1px solid #F78B8B;
    height: 110px;
  }
`;
const InfoLeft = styled.div`
  margin-right: 40px;
  position: relative;

  a {
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: #F4AFAC;
    position: absolute;
    right: 10px;
    bottom: 10px;

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url(${infoArrow});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100%;
    margin-right: 0px;
  }
`;
const Circle = styled.div`
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  @media screen and (max-width: 800px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 420px) {
    border: none;
    background: none;
    display: block;
    margin: 10px;
    width: fit-content;
    height: fit-content;
  }
`;
const Icon = styled.img`
  width: 64px;
  height: 64px;
`;
const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
const Number = styled.span`
  color: #ff6969;
  font-family: "Saira Condensed";
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;
const Name = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  margin-top: 13px;
`;
const LinkButton = styled.button`
  margin-top: 29px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #c4c4c4;
  width: 132px;
  border-top: 1px solid #c4c4c4;
  height: 45px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: visible;
  transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  :hover {
    color: #5a5a5a;
    :before {
      width: 100%;
    }
  }

  :before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    right: auto;
    z-index: 1;
    background-color: #5a5a5a;
    width: 0%;
    height: 1px;
    -webkit-transition: width 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: width 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`;

const InformationComponent = () => (
  <Information>
    <PageWrapper>
      <InformationWrapper>
        <SectionTitle>INFORMATION</SectionTitle>
        <SectionSubtitle>신뢰를 바탕으로 함께 합니다.</SectionSubtitle>

        <Main>
          <Left>
            <ChpaterCubeGap />
            <Cubes />
          </Left>
          <Right>
            <Content>
              &nbsp;
              <br />
              &nbsp;
            </Content>
          </Right>
        </Main>
        <InfoList>
          {infoList.map((info) => (
            <Info info={info} key={info.name} />
          ))}
        </InfoList>
        <Link to="/mobile/pr">
          <PinkButton name="PR 바로가기" width="212" />
        </Link>
      </InformationWrapper>
    </PageWrapper>
  </Information>
);

const Information = styled.div`
  padding-top: 360px;
  position: relative;
  padding-bottom: 140px;
  @media screen and (max-width: 1450px) {
    padding-top: 200px;
  }
  @media screen and (max-width: 800px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 420px) {
    padding-bottom: 80px;
    padding-top: 0px;
  }
`;
const InformationWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1450px) {
    margin: 0;
  }
`;

const SectionTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
  @media screen and (max-width: 420px) {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 42px;
    color: #000000;
  }
`;
const SectionSubtitle = styled.p`
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  margin-top: 20px;
  @media screen and (max-width: 420px) {
    font-family: 'S-Core Dream';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: #383838;
    margin-top: 10px;
  }
`;

const Main = styled.div`
  display: flex;
  margin-top: 90px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;
  @media screen and (max-width: 800px) {
    align-items: flex-start;
  }
`;
const Right = styled.div``;
const ChpaterCubeGap = styled.div`
  display: none;
  height: 40px;
  width: 1;
  @media screen and (max-width: 800px) {
    display: block;
  }
  @media screen and (max-width: 420px) {
    height: 50px;
  }
`;
const Content = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 15px;
    height: 0px;
  }
`;

const InfoList = styled.div`
  margin-left: 150px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 90px;
  @media screen and (max-width: 1200px) {
    margin-left: -100px;
    justify-content: flex-end;
  }
  @media screen and (max-width: 900px) {
    margin-left: -20px;
    justify-content: center;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 50px;
    gap: 9px;
  }
`;

export default InformationComponent;
