import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames/bind";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import styled from "styled-components";

import whiteLogo from "../../assets/images/white-logo.png";

import styles from "../../styles/SignUp.module.scss";

const Tip = styled.span`
  color: #808080;
  font-size: 12px;
`;

const cx = classNames.bind(styles);
const data = observable(
  {
    username: "",
    password: "",
    passwordConfirm: "",
    isPending: false,
    handleChange(event) {
      const { name, value } = event.target;
      data[name] = value;
    },
    handlePending(val) {
      this.isPending = val;
    },
  },
  { handleChange: action, handlePending: action }
);

const url = document.URL;
const token = new URL(url).searchParams.get("token");

const validatePassword = (character) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(character);
};

const handleSubmit = (e) => {
  e.preventDefault();
  const { password, passwordConfirm } = data;

  if (!validatePassword(password))
    return alert("비밀번호는 숫자+영문 조합하여 8자리 이상이어야 합니다.");
  if (password !== passwordConfirm)
    return alert("비밀번호가 일치하지 않습니다.");

  data.handlePending(true);
  axios
    .post("https://api.innofit.kr/accounts/users/", { password })
    .then((res) => {
      if (res.status) {
        window.location.href = "/welcome?token=" + token;
      } else {
        alert("회원가입 실패");
      }
    })
    .catch((err) => {
      alert("회원가입 실패");
    })
    .finally(() => {
      data.handlePending(false);
    });
};

const SignUp = () => {
  if (!token) {
    window.location.href = "/";
  }
  return (
    <div className={cx("body")}>
      <Helmet>
        <title>회원가입 | innofit</title>
      </Helmet>
      <main className={cx("main")}>
        <div className={cx("head")}>
          <img src={whiteLogo} alt="logo" />
        </div>
        <div className={cx("form-wrapper")}>
          <p className={cx("form-title")}>이노핏 회원가입</p>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className={cx("input-wrapper")}>
              <input
                type="password"
                name="password"
                placeholder="비밀번호"
                onChange={(e) => {
                  data.handleChange(e);
                }}
              />
              <Tip>숫자+영문 조합하여 8자리 이상</Tip>
            </div>
            <div className={cx("input-wrapper")}>
              <input
                type="password"
                name="passwordConfirm"
                placeholder="비밀번호 확인"
                onChange={(e) => {
                  data.handleChange(e);
                }}
              />
            </div>
            <button
              className={cx("btn-submit")}
              type="submit"
              disabled={data.isPending}
            >
              <span>가입하기</span>
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default observer(SignUp);
