import { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Chapter, Cubes } from "../molecules";
import { PageWrapper } from "../atoms";
import { ProductViewer } from "../organisms";

import characteristics from "../../assets/images/product/characteristics.png";
import characteristicsMob from "../../assets/images/product/characteristics_mob.png";

// import gmp from "../../assets/images/product/logo/gmp.png";
// import kipo from "../../assets/images/product/logo/KIPO.png";
// import foodSafe from "../../assets/images/product/logo/foodsafe.png";

// import gmpMob from "../../assets/images/product/logo/gmp-mob.png";
// import kipoMob from "../../assets/images/product/logo/KIPO-mob.png";
// import foodSafeMob from "../../assets/images/product/logo/foodsafe-mob.png";

import mouse from "../../assets/images/product/mouse.png";
import mouseLeft from "../../assets/images/product/mouse-left.png";
import mouseRight from "../../assets/images/product/mouse-right.png";
import innofit from "../../assets/images/product/Innofit.png";
import innofitGuide from "../../assets/images/product/Innofit_Guide.png";

import guarantee from "../../assets/images/product/guarantee.png";
import guaranteeMob from "../../assets/images/product/guaranteeMob.png";

const Mouse = ({ isActive }) => <MouseImage isActive={isActive} />;

const ProductComponent = () => {
  // 상품 hover하면 옆에 "제품을 회전시켜 보세요!" 의 마우스 화살표 애니메이션을 위해
  const [mouseActive1, setMouseActive1] = useState(false);
  const [mouseActive2, setMouseActive2] = useState(false);

  return (
    <Product>
      <GreyBackgroundBox />
      <WhiteBackgroundBox />
      <GreyBackground />
      <PageWrapper>
        <ProductPageWrapper>
          <First>
            <PageTitle>innofit SOLUTION</PageTitle>
            <FirstWrapper>
              <FirstBackground>OUR PRODUCT</FirstBackground>
              <FirstLeft>
                <Cubes />
                <FirstContent>
                  이노핏은 모두를 위한 <br />
                  맞춤형 솔루션을 제공합니다.
                </FirstContent>
              </FirstLeft>
              <FirstRight>
                이노핏 코 보형물은 CT영상 데이터를 기반으로 <WebBR />
                개개인의 해부학적 구조에 맞게 제작됩니다. <WebBR />
                의료진은 이노핏 코 성형 가이드를 통해 <WebBR />
                보형물의 진로, 위치 등 수술 계획을 <WebBR />
                수술 중 직관적으로 확인할 수 있습니다.
              </FirstRight>
            </FirstWrapper>
          </First>
          <Second id="innofit">
            <Chapter name="PRODUCT 01" />
            <SectionTitle>이노핏 코 보형물</SectionTitle>
            <ProductMain>
              <ProductViewerWrapper>
                <div
                  onMouseOver={() => setMouseActive1(true)}
                  onMouseOut={() => setMouseActive1(false)}
                >
                  <ProductViewer type="innofit" />
                </div>
                <MouseWrapper>
                  <MouseImage isActive={mouseActive1} />
                  <MouseDesc>제품을 회전시켜 보세요!</MouseDesc>
                </MouseWrapper>
              </ProductViewerWrapper>
              <InnofitViewerMobile src={innofit} />
              <ProductWrapper>
                <ProductLeft>
                  <ProductName>고형이식의료용실리콘재료</ProductName>
                  <SpecLine>SPEC</SpecLine>
                  <SpecWrapper>
                    <SpecDL>
                      <SpecDT>크기</SpecDT>
                      <SpecDD>사람에 따라 다름 (기본 크기 문의)</SpecDD>
                    </SpecDL>
                    <SpecDL>
                      <SpecDT>무게</SpecDT>
                      <SpecDD>사람에 따라 다름 (기본 무게 문의)</SpecDD>
                    </SpecDL>
                    <SpecDL>
                      <SpecDT>색깔</SpecDT>
                      <SpecDD>흰색</SpecDD>
                    </SpecDL>
                    {/* <SpecDL>
                      <SpecDT>성분</SpecDT>
                      <SpecDD>
                        실리콘 (FDA 승인소재)
                        <br />
                        29일이상 신체에 삽입 가능
                      </SpecDD>
                    </SpecDL> */}
                  </SpecWrapper>
                </ProductLeft>
                <ProductRight>
                  {/* <LogoWrapper>
                    <Logo src={gmp} />
                    <Logo src={foodSafe} />
                    <Logo src={kipo} />
                  </LogoWrapper>
                  <LogoWrapperMob>
                    <GMPMob src={gmpMob} />
                    <FoodsafeMob src={foodSafeMob} />
                    <KipoMob src={kipoMob} />
                  </LogoWrapperMob> */}
                  <ProductCertification>
                    <li>· 식품의약품안전처 의료기기 허가</li>
                    <li>· 맞춤형 코 보형물 제작 특허 보유</li>
                    <li>· GMP 제조시설 인증</li>
                  </ProductCertification>
                  <ProductContent>
                    이식형의 단단한 재료로서 환자 맞춤형으로 <br/>
                    코의 성형 및 치료를 목적으로 인체에 삽입하여 사용합니다. <br />
                    CT영상 데이터를 기반으로 맞춤형 형상을 <br/>
                    모델링하여 제작됩니다.
                  </ProductContent>
                </ProductRight>
              </ProductWrapper>
            </ProductMain>
          </Second>
          <Third id="guide">
            <Chapter name="PRODUCT 02" />
            <SectionTitle>이노핏 코 성형 가이드</SectionTitle>
            <ProductMain>
              <ProductViewerWrapper>
                <div
                  onMouseOver={() => setMouseActive2(true)}
                  onMouseOut={() => setMouseActive2(false)}
                >
                  <ProductViewer type="guide" />
                </div>
                <MouseWrapper>
                  <Mouse isActive={mouseActive2} />
                  <MouseDesc>제품을 회전시켜 보세요!</MouseDesc>
                </MouseWrapper>
              </ProductViewerWrapper>
              <GuideViewerMobile src={innofitGuide} />
              <ProductWrapper>
                <ProductLeft>
                  <ProductName>
                    의료용 가이드
                  </ProductName>
                  <SpecLine>SPEC</SpecLine>
                  <SpecWrapper>
                    <SpecDL>
                      <SpecDT>크기</SpecDT>
                      <SpecDD>사람에 따라 다름 (기본 크기 문의)</SpecDD>
                    </SpecDL>
                    <SpecDL>
                      <SpecDT>무게</SpecDT>
                      <SpecDD>사람에 따라 다름 (기본 무게 문의)</SpecDD>
                    </SpecDL>
                    <SpecDL>
                      <SpecDT>색깔</SpecDT>
                      <SpecDD>흰색</SpecDD>
                    </SpecDL>
                  </SpecWrapper>
                </ProductLeft>
                <ProductRight>
                  {/* <LogoWrapper>
                    <Logo src={gmp} />
                    <Logo src={foodSafe} />
                    <Logo src={kipo} />
                  </LogoWrapper>
                  <LogoWrapperMob>
                    <GMPMob src={gmpMob} />
                    <FoodsafeMob src={foodSafeMob} />
                    <KipoMob src={kipoMob} />
                  </LogoWrapperMob> */}
                  <ProductCertification>
                    <li>· 식품의약품안전처 의료기기 신고</li>
                    <li>· 수술용 가이드 제작 특허 보유</li>
                    <li>· GMP 제조시설 인증</li>
                  </ProductCertification>
                  <ProductContent>
                    보형물의 진로, 위치, 수술부위의 표시 등을 <br />
                    안내하기 위하여 사용하는 수술 보조 기구로, <br />
                    환자의 의료영상 데이터를 기반으로 <br />
                    맞춤형 형상을 모델링해 제작됩니다.
                  </ProductContent>
                </ProductRight>
              </ProductWrapper>
            </ProductMain>
          </Third>
        </ProductPageWrapper>
      </PageWrapper>
      <Fourth>
        <PinkBackground />
        <PageWrapper>
          <ProductPageWrapper>
            <FourthWrapper>
              <FourthLeft>
                <Chapter name="Characteristics" />
                <SectionTitle>Why innofit?</SectionTitle>
              </FourthLeft>
              <FourthRight>
                <FourthRightWrapper>
                  <FourthRightLeft>
                    <Characteristics src={characteristics} />
                    <CharacteristicsMob src={characteristicsMob} />
                  </FourthRightLeft>
                  <FourthRightRight>
                    {/* <FourthTitle>
                      CT 영상 분석 및 3D 모델링으로 해부학적 구조를 반영하여
                      안정적 밀착감을 제공합니다.
                    </FourthTitle> */}
                    <FourthContent>
                      <ul>
                        <li>GMP 인증 시설에서 제작</li>
                        <li>해부학적 구조를 반영해 3D 프린터로 맞춤 제작</li>
                        <li>보형물 들뜸 완화 및 안정적 밀착감 제공</li>
                        <li>다양한 코 유형별 맞춤 보형물 제작 가능</li>
                      </ul>
                    </FourthContent>
                  </FourthRightRight>
                </FourthRightWrapper>
              </FourthRight>
            </FourthWrapper>
          </ProductPageWrapper>
        </PageWrapper>
      </Fourth>
      <Fifth>
        <FifthBack1/>
        <FifthBack2/>
        <FifthBack3/>
        <PageWrapper>
          <ProductPageWrapper>
            <FifthWrapper>
              <FifthTop>
                <Chapter name="GUARANTEE CARD" />
                <SectionTitle>정품인증서</SectionTitle>
              </FifthTop>
              <FifthBottom>
                <FifthContent>
                  <FifthStrong>
                    정품인증카드는 고객과의 약속입니다
                  </FifthStrong>
                  이노핏 고객에게는 정품인증카드를 제공합니다.
                  <br />
                  정품인증카드를 꼭 수령해 주세요.
                  <FifthSubcontent>* 이노핏 정품인증서 2022년 1월부터 시행</FifthSubcontent>
                </FifthContent>
                <FifthImg src={guarantee} />
                <FifthImgMob src={guaranteeMob} />
              </FifthBottom>
              <FifthSubcontent>* 이노핏 정품인증서 2022년 1월부터 시행</FifthSubcontent>
            </FifthWrapper>
          </ProductPageWrapper>
        </PageWrapper>
      </Fifth>
    </Product>
  );
};

const WebBR = styled.br``;
const Product = styled.div``;
const ProductPageWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const GreyBackgroundBox = styled.div`
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  position: absolute;
  right: 0;
  top: 420px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    top: 700px;
  }
`;
const WhiteBackgroundBox = styled.div`
  width: 180px;
  height: 180px;
  right: 0;
  top: 420px;
  position: absolute;
  border-radius: 0px 0px 180px 0;
  background: #fff;
  z-index: -1;
  @media screen and (max-width: 800px) {
    top: 700px;
    border-radius: 0px 0px 100px 0;
  }
`;
const GreyBackground = styled.div`
  background: #f3f3f3;
  position: absolute;
  right: 0;
  border-radius: 180px 0px 0px 180px;
  height: 2300px;
  width: 80vw;
  z-index: -1;
  top: 600px;
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    height: 2589px;
    top: 880px;
    border-radius: 100px 0px 0px 100px;
  }
`;

const First = styled.div`
  padding-top: 140px;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
  }
`;
const FirstBackground = styled.p`
  position: absolute;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 120px;
  line-height: 130px;
  color: #f3f3f3;
  right: 0;
  top: -85px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    font-size: 100px;
    line-height: 90px;
    top: 260px;
  }
`;
const PageTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 48px;
  line-height: 42px;
  color: #383838;
`;
const FirstWrapper = styled.div`
  display: flex;
  position: relative;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 60px;
  }
`;
const FirstLeft = styled.div`
  margin-right: 160px;
  margin-top: 70px;
  max-width: 420px;
  flex: 1;
  @media screen and (max-width: 1100px) {
    margin-right: 80px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 0;
    margin-top: 0;
    max-width: unset;
  }
`;
const FirstContent = styled.p`
  min-width: 341px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 16px;
`;
const FirstRight = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #989898;
  flex: 1;
  @media screen and (max-width: 1100px) {
    max-width: 380px;
    ${WebBR} {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    margin-top: 20px;
    max-width: unset;
  }
`;

const ProductViewerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const InnofitViewerMobile = styled.img`
  display: none;
  width: 183px;
  height: 357px;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;
const GuideViewerMobile = styled.img`
  display: none;
  width: 226px;
  height: 356px;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;
const MouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 145px;
  margin-bottom: 50px;
  @media screen and (max-width: 1100px) {
    margin-left: 65px;
  }
`;

const mouseLeftAni = keyframes`
  0%{
    left: -40px;
  }
  100%{
    left: -50px;
  }
`;
const mouseRightAni = keyframes`
  0%{
    right: -40px;
  }
  100%{
    right: -50px;
  }
`;
const MouseImage = styled.div`
  width: 46px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 75px;
  position: relative;
  background: url(${mouse});
  margin-left: 40px;
  :before {
    position: absolute;
    content: url(${mouseLeft});
    width: 25px;
    height: 20px;
    top: 30px;
    left: -40px;
    ${({ isActive }) =>
      isActive &&
      css`
        animation: ${mouseLeftAni} 0.3s 0s infinite ease-in alternate;
      `};
  }
  :after {
    position: absolute;
    content: url(${mouseRight});
    width: 25px;
    height: 20px;
    top: 30px;
    right: -40px;
    ${({ isActive }) =>
      isActive &&
      css`
        animation: ${mouseRightAni} 0.3s 0s infinite ease-in alternate;
      `};
  }
`;
const MouseDesc = styled.p`
  margin-top: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #5a5a5a;
`;

const ProductMain = styled.div`
  margin: 0px auto;
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 800px) {
    margin-top: 90px;
    padding: 0 20px;
  }
`;
const ProductWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const ProductLeft = styled.div`
  margin-right: 80px;
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;
const ProductRight = styled.div`
  @media screen and (max-width: 800px) {
    margin-top: 40px;
  }
`;
const ProductCertification = styled.ul`
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #5a5a5a;
`;
const ProductContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #989898;
  margin-top: 20px;
  @media screen and (max-width: 800px) {
    ${WebBR} {
      display: none;
    }
  }
  @media screen and (max-width: 375px) {
    br {
      display: none;
    }

    br:first-child {
      display: unset;
    }
  }
`;
const ProductName = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.05em;
  color: #383838;
  position: relative;

  sub {
    position: absolute;
    top: 28px;
    left: 0px;
    font-size: 15px;
    font-weight: 400;
    color: #5A5A5A;
  }

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;
const SpecLine = styled.div`
  margin-top: 52px;
  font-family: "Saira Condensed";
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #f2bb91;
  position: relative;
  :after {
    width: 120px;
    height: 1px;
    background: #f2bb91;
    content: "";
    position: absolute;
    top: 11px;
    left: 50px;
  }
`;
const SpecWrapper = styled.div`
  margin-top: 20px;
`;

const SpecDL = styled.dl`
  display: flex;
`;
const SpecDT = styled.dt`
  font-weight: 500;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-right: 20px;
  @media screen and (max-width: 800px) {
    line-height: 32px;
  }
`;
const SpecDD = styled.dd`
  font-weight: 400;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  @media screen and (max-width: 800px) {
    line-height: 32px;
  }
`;

// const LogoWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   height: 105px;
//   img + img {
//     margin-left: 40px;
//   }
//   @media screen and (max-width: 800px) {
//     display: none;
//   }
// `;
// const LogoWrapperMob = styled.div`
//   display: none;
//   align-items: center;
//   img + img {
//     margin-left: 40px;
//   }
//   @media screen and (max-width: 800px) {
//     display: flex;
//     align-items: center;
//   }
// `;

// const Logo = styled.img`
//   @media screen and (max-width: 800px) {
//     display: none;
//   }
// `;
// const LogoMob = styled.img``;
// const GMPMob = styled(LogoMob)`
//   width: 73px;
//   height: 60px;
// `;
// const FoodsafeMob = styled(LogoMob)`
//   height: 70px;
// `;
// const KipoMob = styled(LogoMob)`
//   height: 70px;
// `;

const Second = styled.div`
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 209px;
  }
`;
const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  margin-top: 10px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
`;
const Third = styled.div`
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
`;

const Fourth = styled.div`
  position: relative;
`;
const FourthWrapper = styled.div`
  margin-top: 140px;
  display: flex;
  @media screen and (max-width: 1140px) {
    flex-direction: column;
  }
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
`;

const Characteristics = styled.img`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const CharacteristicsMob = styled.img`
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;

const FourthLeft = styled.div`
  margin-right: 125px;
`;
const FourthRight = styled.div`
  margin-bottom: 170px;
  padding-top: 30px;
  @media screen and (max-width: 1140px) {
    padding-top: 0px;
    margin-top: 70px;
    margin-left: 30px;
  }
  @media screen and (max-width: 800px) {
    background: #f78b8b;
    padding: 30px;
    border-radius: 40px 0px 0px 40px;
    margin-right: -20px;
    margin-left: 0;
    margin-bottom: 100px;
  }
`;

const PinkBackground = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #f78b8b;
  border-radius: 40px 0px 0px 40px;
  height: 300px;
  width: calc(50vw + 290px);
  z-index: -1;
  @media screen and (max-width: 1450px) {
    width: calc(100vw - 380px);
  }
  @media screen and (max-width: 1140px) {
    width: calc(100vw - 60px);
    top: 124px;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const FourthRightWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const FourthRightLeft = styled.div`
  margin-right: 40px;
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;
const FourthRightRight = styled.div`
  width: 100%;
  max-width: 423px;
  @media screen and (max-width: 800px) {
    margin-top: 40px;
  }
`;
// const FourthTitle = styled.p`
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 32px;
//   letter-spacing: -0.05em;
//   color: #ffffff;
// `;
const FourthContent = styled.div`
  font-weight: 200;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #f3f3f3;
  padding-left: 18px;

  ul {
    list-style: unset;
  }
`;


const Fifth = styled.div`
  position: relative;
`;
const FifthBack1 = styled.div`
  position: absolute;
  top: -180px;
  width: 180px;
  height: 180px;
  background-color: #f3f3f3;
  z-index: -3;
  @media screen and (max-width: 800px) {
    top: -100px;
    width: 100px;
    height: 100px;
  }
`;
const FifthBack2 = styled.div`
  position: absolute;
  top: -180px;
  width: 180px;
  height: 180px;
  background-color: white;
  border-bottom-left-radius: 180px;
  z-index: -2;
  @media screen and (max-width: 800px) {
    top: -100px;
    width: 100px;
    height: 100px;
    border-bottom-left-radius: 100px;
  }
`;
const FifthBack3 = styled.div`
  position: absolute;
  width: 80vw;
  height: 100%;
  background-color: #f3f3f3;
  border-top-right-radius: 180px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    border-top-right-radius: 100px;
  }
`;
const FifthSubcontent = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: #989898;
  margin-top: 50px;
`;
const FifthWrapper = styled.div`
  > ${FifthSubcontent} {
    display: none;
  }

  @media screen and (max-width: 800px) {
    padding-bottom: 100px;

    > ${FifthSubcontent} {
      display: unset;
    }
  }
`;
const FifthTop = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;
`;
const FifthBottom = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  padding-bottom: 100px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 6px;
  }
`;
const FifthImg = styled.img`
  margin-right: 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const FifthImgMob = styled.img`
  display: none;
  @media screen and (max-width: 800px) {
    display: unset;
    width: 80%;
  }
`;
const FifthContent = styled.div`
  line-height: 32px;
  letter-spacing: -0.05em;
  font-weight: 400;
  color: #5A5A5A;
  margin-bottom: 40px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 28px;

    ${FifthSubcontent} {
      display: none;
    }
  }
`;
const FifthStrong = styled.strong`
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #383838;
  margin-bottom: 20px;
`;

export default ProductComponent;
