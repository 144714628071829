import styled from "styled-components";
import { Link } from "react-router-dom";
import { PageWrapper } from "../../atoms";
import { BeigeButton } from "../../molecules/buttons";
import { Cubes } from "../../molecules";
import brand1 from "../../../../assets/images/landing/brand1.png";
import brand2 from "../../../../assets/images/landing/brand2.png";
import brand3 from "../../../../assets/images/landing/brand3.png";
import brand4 from "../../../../assets/images/landing/brand4.png";

const BrandComponent = () => {
  return (
    <Brand>
      <GreyBackground />
      <GreyBackgroundBottom />
      <WhiteBackgroundBottom />
      <PageWrapper>
        <Title>BRAND STORY</Title>
        <SubTitle>우리는 당신과의 동행을 생각합니다.</SubTitle>
        <BrandWrapper>
          <Left>
            <BrandImage1 src={brand1} />
            <BrandImage2 src={brand2} />
            <BrandImage3 src={brand3} />
            <BrandImage4 src={brand4} />
          </Left>
          <Right>
            <RightMain>
              <Cubes />
              <Content>
                이노핏은 나에게 맞는 <br />
                아름다움을 찾고, 이를 구현합니다.
              </Content>
              {/* <SubContent>
                우리는 이 질문에서부터 시작했습니다.
              </SubContent> */}
            </RightMain>
            <Link to="/mobile/story">
              <BeigeButton name="BRAND STORY 가기" />
            </Link>
          </Right>
        </BrandWrapper>
      </PageWrapper>
    </Brand>
  );
};

const Brand = styled.div`
  padding-top: 140px;
  padding-bottom: 270px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
    padding-bottom: 190px;
  }
  @media screen and (max-width: 420px) {
    padding-top: 80px;
    padding-bottom: 230px;
  }
`;
const GreyBackground = styled.div`
  background: #f3f3f3;
  border-radius: 0px 0px 0px 180px;
  position: absolute;
  height: 915px;
  width: 55vw;
  top: 0;
  right: 0;
  z-index: -1;
  @media screen and (max-width: 1450px) {
    width: 62vw;
  }
  @media screen and (max-width: 1100px) {
    width: 70vw;
    height: 840px;
  }
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    height: 1050px;
    border-radius: 0px 0px 0px 100px;
    left: 20px;
  }
  @media screen and (max-width: 420px) {
    height: 840px;
    border-bottom-left-radius: 80px;
  }
`;
const WhiteBackgroundBottom = styled.div`
  position: absolute;
  top: 915px;
  right: 0;
  background: #fff;
  width: 210px;
  height: 210px;
  border-radius: 0px 180px 0 0;
  z-index: -1;
  @media screen and (max-width: 1100px) {
    top: 840px;
  }
  @media screen and (max-width: 800px) {
    top: 1050px;
    border-radius: 0px 100px 0px 0px;
  }
  @media screen and (max-width: 420px) {
    top: 840px;
    border-top-right-radius: 80px;
  }
`;
const GreyBackgroundBottom = styled.div`
  position: absolute;
  top: 915px;
  right: 0;
  background: #f3f3f3;
  width: 210px;
  height: 210px;
  z-index: -1;
  @media screen and (max-width: 1100px) {
    top: 835px;
  }
  @media screen and (max-width: 800px) {
    top: 1000px;
  }
  @media screen and (max-width: 420px) {
    top: 840px;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 48px;
  font-family: "Saira Condensed";
  margin-left: 124px;
  @media screen and (max-width: 1100px) {
    margin-left: 0;
  }
  @media screen and (max-width: 420px) {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 42px;
  }
`;
const SubTitle = styled.p`
  font-weight: 300;
  font-size: 15px;
  margin-top: 20px;
  letter-spacing: -0.05em;
  margin-left: 124px;
  @media screen and (max-width: 1100px) {
    margin-left: 0;
  }
  @media screen and (max-width: 420px) {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #383838;
    margin-top: 10px;
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  margin-top: 90px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 70px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 50px;
  }
`;

const Left = styled.div``;
const BrandImage1 = styled.img`
  @media screen and (max-width: 1450px) {
    display: none;
  }
`;
const BrandImage2 = styled.img`
  display: none;
  @media screen and (max-width: 1450px) {
    display: inline-block;
  }
  @media screen and (max-width: 1100px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
  }
`;
const BrandImage3 = styled.img`
  display: none;
  @media screen and (max-width: 1100px) {
    display: inline-block;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const BrandImage4 = styled.img`
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;

const Right = styled.div`
  margin-left: 84px;
  @media screen and (max-width: 1450px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 60px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
`;
const RightMain = styled.div`
  margin: 80px 0;
  @media screen and (max-width: 1100px) {
    margin: 40px 0;
  }
  @media screen and (max-width: 420px) {
    margin: 50px 0;
  }
`;
const Content = styled.p`
  color: #383838;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  margin-top: 16px;
  font-weight: 500;
  @media screen and (max-width: 1100px) {
    font-size: 22px;
    line-height: 38px;
  }
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 42px;
    br {
      display: none;
    }
  }
  @media screen and (max-width: 420px) {
    margin-top: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.05em;
    color: #383838;
    
    br {
      display: unset;
    }
  }
`;
// const SubContent = styled.p`
//   font-size: 18px;
//   line-height: 32px;
//   letter-spacing: -0.05em;
//   color: #5a5a5a;
//   margin-top: 20px;
//   font-weight: 400;
//   @media screen and (max-width: 800px) {
//     br {
//       display: none;
//     }
//   }
//   @media screen and (max-width: 420px) {
//     font-family: 'S-Core Dream';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 26px;
//     letter-spacing: -0.05em;
//     color: #5A5A5A;

//     br {
//       display: unset;
//     }
//   }
// `;
export default BrandComponent;
