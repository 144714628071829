import styled from "styled-components";

const Chapter = ({ name }) => (
  <ChapterWrapper>
    {name}
    <Line />
  </ChapterWrapper>
);

const ChapterWrapper = styled.div`
  color: #f78b8b;
  font-size: 15px;
  line-height: 24px;
  font-family: "Saira Condensed";
  display: flex;
  align-items: center;
  @media screen and (max-width: 420px) {
    width: fit-content;
    border-bottom: 1px solid #f78b8b;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
  }
`;
const Line = styled.div`
  width: 120px;
  height: 1px;
  background: #f78b8b;
  margin-left: 14px;
  @media screen and (max-width: 420px) {
    display: none;
  }
`;

export default Chapter;
