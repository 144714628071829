import styled from "styled-components";
import { useEffect, useRef, useMemo } from "react";
import ScrollBooster from "scrollbooster";

const ProductViewerComponent = ({ type }) => {
  const productInfo = {
    innofit: {
      imgH: 500,
      imgW: 257,
      imagePrefix: "/images/product1/Innofit_",
    },
    guide: {
      imgH: 488,
      imgW: 310,
      imagePrefix: "/images/product2/Innofit_Guide_",
    },
  };

  const { imgH, imgW, imagePrefix } = productInfo[type];

  const canvas = useRef();
  const wrapper = useRef();
  const content = useRef();

  // public/images/ 폴더에 있는 이미지 주소 리스트화
  const frameCount = 50;
  // 부모 컴포넌트에서 재렌더링 되어도 이미지 리스트 초기화 안 되도록 useMemo사용
  const frames = useMemo(
    () =>
      "1"
        .repeat(frameCount)
        .split("")
        .map((e, i) => {
          const zero = 2 - (i + "").length;
          return imagePrefix + "0".repeat(zero) + (i + 1) + ".png";
        }),
    [imagePrefix]
  );

  const img = new Image();
  img.onload = function () {
    // 이미지 로드 완료 되면 캔버스에 그리기
    const context = canvas.current.getContext("2d");
    context.drawImage(img, 0, 0, imgW, imgH);
  };

  useEffect(() => {
    // 마우스 드래그로 가로 스크롤 하는 라이브러리
    new ScrollBooster({
      viewport: wrapper.current,
      content: content.current,
      direction: "horizontal",
      scrollMode: "native",
    });
  }, []);

  useEffect(() => {
    // 이미지 미리 로딩해놓기
    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        const tempImg = new Image();
        tempImg.src = frames[i];
      }
    };
    preloadImages();

    // 스크롤 중간에서 시작
    wrapper.current.scrollLeft = 300;
  }, [frames]);

  const updateImage = (index) => {
    // 이미지 소스 변경
    img.src = frames[index];
  };

  const scrollEvent = () => {
    // 스크롤 될 때 마다 이미지 인덱스 계산, 변경
    const scrollLeft = wrapper.current.scrollLeft;
    const maxScrollLeft = wrapper.current.scrollWidth - 300;
    const scrollFraction = scrollLeft / maxScrollLeft;
    const frameIndex = Math.min(
      frameCount - 1,
      Math.ceil(scrollFraction * frameCount)
    );

    requestAnimationFrame(() => updateImage(frameIndex));
  };

  const handleMouseGrab = () => {
    // 마우스 누르고 있을 때 커서 잡고있는 모양으로 교체
    wrapper.current.style.cursor = "grabbing";
    window.document.body.style.cursor = "grabbing";
    window.document.body.style.userSelect = "none";

    // 마우스 땟을때의 이벤트를 브라우저 전체에 등록
    window.document.addEventListener("mouseup", handleRemoveMouseGrab);
  };

  const handleRemoveMouseGrab = () => {
    // 마우스 뗐을 때 커서 모양 설정 해제
    wrapper.current.style.cursor = "grab";
    window.document.body.style.removeProperty("cursor");
    window.document.body.style.removeProperty("user-select");
    window.document.removeEventListener("mouseup", handleRemoveMouseGrab);
  };

  return (
    <ProductViewerWrapper>
      <ProductViewer
        ref={wrapper}
        onScroll={scrollEvent}
        onMouseDown={handleMouseGrab}
        width={imgW}
        height={imgH}
      >
        <ProductViewerScroller ref={content} />
      </ProductViewer>
      <ProductCanvas ref={canvas} width={imgW} height={imgH} />
    </ProductViewerWrapper>
  );
};
const ProductViewerWrapper = styled.div`
  position: relative;
`;
const ProductViewer = styled.div`
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  overflow-x: auto;
  position: relative;
  cursor: grab;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ProductViewerScroller = styled.div`
  width: 1000px;
  height: 100%;
`;
const ProductCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export default ProductViewerComponent;
