import styled from "styled-components";
import { PageWrapper } from "../atoms";
import { Cubes } from "../molecules";

import phone from "../../../assets/images/icons/partner_phone.png";
import mail from "../../../assets/images/icons/mail_outline.png";

import React, { useEffect, useState } from 'react';
import axios from 'axios'

function MobileMembership() {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/Partner`).then(item => {
      setList(item.data)
    })
  }, [])


  return (
    <Partners>
      <GreyBackgroundTop />
      <GreyBackgroundBoxTop />
      <WhiteBackgroundBoxTop />
      <PageWrapper>
        <PartnersWrapper>
          <PageTitle>WITH US</PageTitle>
          <PageContentWrapper>
            <BackgroundText>Membership</BackgroundText>
            <Cubes />
            <PageContent>이노핏과 동행하는 병원</PageContent>
          </PageContentWrapper>
          <PartnerList>
            {list.map((p, i) => (
              <PartnerWrapper key={i}>
                <a href={p.purl} target="_blank" rel="noreferrer">
                  <PartnerLogo src={p.pimg} />
                  <PartnerLogoMob src={p.pimg} />
                </a>
              </PartnerWrapper>
            ))}
          </PartnerList>
          <PageBottom>
            <BottomMessage>
              이노핏과 함께할
              <br />
              멤버쉽을 모집합니다.
            </BottomMessage>
            <BottomRight>
              <CallInfo>멤버십 병원 상담 문의</CallInfo>
              <BottomRightBottom>
                <a href='tel:02-421-1314'>
                  <PhoneInfo>
                    <PhoneImage src={phone} />
                    <PhoneNumber>02.421.1314</PhoneNumber>
                  </PhoneInfo>
                </a>
                <a href='mailto:sales@anymedi.com'>
                  <EmailInfo>
                    <MailImage src={mail} />
                    <Mail>sales@anymedi.com</Mail>
                  </EmailInfo>
                </a>
              </BottomRightBottom>
            </BottomRight>
          </PageBottom>
        </PartnersWrapper>
      </PageWrapper>
      <GreyBackgroundBottom />
      <GreyBackgroundBoxBottom />
      <WhiteBackgroundBoxBottom />
    </Partners>
  );
}
export default MobileMembership


const Partners = styled.div`
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media screen and (max-width: 420px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
const PartnersWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
const PageTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 52px;
  line-height: 42px;
  color: #383838;
  @media screen and (max-width: 800px) {
    font-size: 48px;
    line-height: 42px;
  }
  @media screen and (max-width: 420px) {
    font-size: 44px;
  }
`;
const PageContentWrapper = styled.div`
  position: relative;
  margin-top: 72px;
  @media screen and (max-width: 420px) {
    margin-top: 50px;
  }
`;
const PageContent = styled.p`
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 16px;
  @media screen and (max-width: 420px) {
    margin-top: 14px;
    font-size: 16px;
    line-height: 26px;
  }
`;
const BackgroundText = styled.p`
  position: absolute;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 150px;
  line-height: 100px;
  color: #f3f3f3;
  z-index: -1;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 900px) {
    font-size: 100px;
    line-height: 100px;
  }
  @media screen and (max-width: 420px) {
    font-size: 70px;
    line-height: 72px;
    top: 18px;
    left: 0px;
  }
`;

const PartnerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 90px;
  @media screen and (max-width: 800px) {
    margin-top: 80px;
  }
`;
const PartnerWrapper = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 900px) {
    width: 50%;
  }
`;
const PartnerLogo = styled.img`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const PartnerLogoMob = styled.img`
  display: none;
  width: ${({ width }) => width + "px"};
  @media screen and (max-width: 600px) {
    display: inline-block;
    width: 88%;
    margin-left: 6%;
  }
`;

const PageBottom = styled.div`
  margin-top: 200px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 120px;
  }
`;
const BottomMessage = styled.p`
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.05em;
  color: #383838;
  @media screen and (max-width: 800px) {
    font-size: 22px;
    line-height: 38px;
  }
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
const BottomRight = styled.div`
  margin-left: 140px;
  @media screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 70px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
`;
const CallInfo = styled.div`
  color: #989898;
  @media screen and (max-width: 420px) {
    width: 195px;
    height: 30px;
    background-color: #f2bb91;
    border-radius: 0px 15px 15px 0px;
    position: relative;
    left: -20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: white;
    text-align: center;
  }
`;
const BottomRightBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    margin-top: 16px;
    align-items: unset;
    margin-left: 10px;
  }
`;
const PhoneInfo = styled.div`
  @media screen and (max-width: 420px) {
    display: flex;
    align-items: center;
  }
`;

const PhoneImage = styled.img`
  width: 42px;
  height: 42px;
  @media screen and (max-width: 420px) {
    width: 30px;
    height: 30px;
  }
`;
const PhoneNumber = styled.span`
  margin-left: 20px;
  font-family: "Saira Condensed";
  font-weight: 200;
  font-size: 48px;
  line-height: 60px;
  color: #5a5a5a;
  @media screen and (max-width: 1100px) {
    font-size: 44px;
  }
  @media screen and (max-width: 420px) {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin-left: 9px;
  }
`;
const EmailInfo = styled.div`
  display: flex;
  margin-left: 60px;
  @media screen and (max-width: 1100px) {
    margin-left: 0;
    margin-top: 20px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 10px;
    align-items: center;
  }
`;
const MailImage = styled.img`
  @media screen and (max-width: 420px) {
    width: 30px;
    height: 30px;
  }
`;
const Mail = styled.span`
  font-family: "Saira Condensed";
  font-weight: 200;
  font-size: 24px;
  line-height: 20px;
  color: #4f4f4f;
  margin-left: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 420px) {
    font-size: 20px;
    line-height: 20px;
    font-weight: 300;
    margin-left: 9px;
  }
`;

const GreyBackgroundTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 45vw;
  height: 80px;
  background: #f3f3f3;
  z-index: -1;
  border-radius: 0px 0px 180px 0px;
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    border-radius: 0px 0px 100px 0px;
  }
  @media screen and (max-width: 420px) {
    border-radius: 0px 0px 80px 0px;
  }
`;
const GreyBackgroundBoxTop = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: 100px;
    height: 100px;
  }
`;
const WhiteBackgroundBoxTop = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 180px;
  height: 180px;
  background: #fff;
  z-index: -1;
  border-radius: 180px 0px 0 0px;
  @media screen and (max-width: 800px) {
    width: 100px;
    height: 100px;
    border-radius: 100px 0px 0 0px;
  }
  @media screen and (max-width: 420px) {
    border-radius: 80px 0px 0 0px;
  }
`;

const GreyBackgroundBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80vw;
  height: 310px;
  background: #f3f3f3;
  z-index: -1;
  border-radius: 180px 0px 0px 0px;
  @media screen and (max-width: 800px) {
    border-radius: 100px 0px 0 0px;
    width: calc(100vw - 20px);
  }
  @media screen and (max-width: 420px) {
    border-radius: 80px 0px 0 0px;
    height: 256px;
  }
`;
const GreyBackgroundBoxBottom = styled.div`
  position: absolute;
  bottom: 310px;
  right: 0;
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  z-index: -1;
  @media screen and (max-width:  420px) {
    bottom: 256px;
  }
`;
const WhiteBackgroundBoxBottom = styled.div`
  position: absolute;
  bottom: 310px;
  right: 0;
  width: 180px;
  height: 180px;
  background: #fff;
  z-index: -1;
  border-radius: 0px 0 180px 0px;
  @media screen and (max-width: 800px) {
    border-radius: 0px 0 100px 0px;
  }
  @media screen and (max-width: 420px) {
    border-radius: 0px 0 80px 0px;
    bottom: 256px;
  }
`;

