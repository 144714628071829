import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from "./components/Layout";
import Landing from "./components/pages/Landing";
import Story from "./components/pages/Story";
import Product from "./components/pages/Product";
import Software from "./components/pages/Software";
// import Event from "./components/pages/Event";
// import Lab from "./components/pages/Lab";
import PR from "./components/pages/PR";
import Membership from "./components/pages/Membership";

import SignUp from "./components/pages/SignUp";
import Password from "./components/pages/Password";
import PasswordSuccess from "./components/pages/PasswordSuccess";
import Welcome from "./components/pages/Welcome";

import Page404 from "./components/pages/Page404";


import MobileLanding from './components/mobile/pages/MobileLanding';
import MobileStory from './components/mobile/pages/MobileStory';
import MobileProduct from './components/mobile/pages/MobileProduct';
import MobileSoftware from './components/mobile/pages/MobileSoftware';
// import MobileEvent from './components/mobile/pages/MobileEvent';
// import MobileLab from './components/mobile/pages/MobileLab';
import MobilePR from './components/mobile/pages/MobilePR';
import MobileMembership from './components/mobile/pages/MobileMembership';

const Routes = () => (
  <Router>
    <Layout>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/story" exact component={Story} />
        <Route path="/product" exact component={Product} />
        <Route path="/software" exact component={Software} />
        {/* <Route path="/event" exact component={Event} /> */}
        {/* 이노핏랩 체험 공간 없애면서 해당 페이지 가리기 */}
        {/* <Route path="/lab" exact component={Lab} /> */}
        <Route path="/pr" exact component={PR} />
        <Route path="/membership" exact component={Membership} />
        
        <Route path="/mobile" exact component={MobileLanding} />
        <Route path="/mobile/story" exact component={MobileStory} />
        <Route path="/mobile/product" exact component={MobileProduct} />
        <Route path="/mobile/software" exact component={MobileSoftware} />
        {/* <Route path="/mobile/event" exact component={MobileEvent} /> */}
        {/* 이노핏랩 체험 공간 없애면서 해당 패이지 가리기 */}
        {/* <Route path="/mobile/lab" exact component={MobileLab} /> */}
        <Route path="/mobile/pr" exact component={MobilePR} />
        <Route path="/mobile/membership" exact component={MobileMembership} />

        <Route path="/signup" exact component={SignUp} />
        <Route path="/password" exact component={Password} />
        <Route path="/password-success" exact component={PasswordSuccess} />
        <Route path="/welcome" exact component={Welcome} />

        <Route path="*" component={Page404} />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
