import styled from "styled-components";
import { PageWrapper } from "../atoms";
import { Cubes } from "../molecules";
import { PinkButton } from "../molecules/buttons";

const SoftwareComponent = () => (
  <Software>
    <PageWrapper>
      <BackgroundText>SOFTWARE</BackgroundText>
      <PageTitle>DESIGN YOURSELF</PageTitle>
      <Cubes center />
      <SoftwareTitle>기술에 상상을 더합니다.</SoftwareTitle>
      <SoftwareSubtitle>
        이노핏의 '가상성형 소프트웨어'로 내가 원하는 라인을 디자인해 보세요.
      </SoftwareSubtitle>

      <a
        download
        href="https://innofit-software.s3.ap-northeast-2.amazonaws.com/innofit_demo_setup.exe"
        className="btn-productswdownload"
      >
        <PinkButton
          name="DEMO 소프트웨어 다운로드"
          arrow={false}
        />
      </a>
      <VideoTitle>가상성형 시연 동영상</VideoTitle>

      <VideoWrapper>
        <Video>
          <iframe 
            width="560"
            height="315"
            src="https://www.youtube.com/embed/v6RcHTVLEgk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Video>
        <VideoDesc>
          * 이노핏 소프트웨어는 성형 전 상담을 위한 참고 도구이며, 수술 계획의 최종 확정을 위해서는 전문의의 판단이 필요합니다.
        </VideoDesc>
      </VideoWrapper>
    </PageWrapper>
    <BottomWrapper>
      <BottomLeft>
        <BottomLeftTop />
        <BottomLeftTopWhite />
      </BottomLeft>
      <BottomRight>
        <BottomRightTop />
        <BottomRightTopWhite />
      </BottomRight>
    </BottomWrapper>
  </Software>
);

const Software = styled.div`
  padding-top: 140px;
  text-align: center;
  position: relative;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
  }
`;
const PageTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 52px;
  line-height: 42px;
  color: #383838;
  margin-bottom: 70px;
  @media screen and (max-width: 800px) {
    font-size: 48px;
  }
`;
const SoftwareTitle = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
`;
const SoftwareSubtitle = styled.p`
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #989898;
  @media screen and (max-width: 500px) {
    text-align: left;
  }
`;

const VideoWrapper = styled.div`
  margin: 0 auto;
  text-align: left;
  width: 100%;
  max-width: 952px;
`;
const VideoTitle = styled.p`
  margin-top: 100px;
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;
const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-top: 30px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const VideoDesc = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  @media screen and (max-width: 800px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

const BackgroundText = styled.p`
  position: absolute;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 120px;
  line-height: 130px;
  color: #f3f3f3;
  right: 0;
  left: 0;
  top: 235px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    font-size: 85px;
    line-height: 100px;
    top: 195px;
  }
`;

const Bottom = styled.div`
  position: relative;
  height: 154px;
  width: 40vw;
  background: #f3f3f3;
  @media screen and (max-width: 800px) {
    height: 80px;
  }
`;
const BottomTop = styled.div`
  width: 163px;
  height: 163px;
  top: -163px;
  background: #f3f3f3;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: 80px;
    height: 80px;
    top: -80px;
  }
`;
const BottomTopWhite = styled.div`
  width: 163px;
  height: 163px;
  top: -163px;
  background: #fff;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: 80px;
    height: 80px;
    top: -80px;
  }
`;

const BottomWrapper = styled.div`
  margin-top: 108px;
  display: flex;
  justify-content: space-between;
`;
const BottomLeft = styled(Bottom)`
  border-radius: 0px 180px 0px 0px;
`;
const BottomLeftTop = styled(BottomTop)`
  left: 0px;
`;
const BottomLeftTopWhite = styled(BottomTopWhite)`
  left: 0px;
  border-radius: 0px 0px 0px 180px;
`;
const BottomRight = styled(Bottom)`
  border-radius: 180px 0px 0px 0px;
`;
const BottomRightTop = styled(BottomTop)`
  right: 0px;
`;
const BottomRightTopWhite = styled(BottomTopWhite)`
  right: 0px;
  border-radius: 0 0 180px 0;
`;

export default SoftwareComponent;
