import styled from "styled-components";

const TermComponent = ({ term }) => {
  const { title } = term;
  return (
    <Modal>
      <ModalTop>{title}</ModalTop>
      <ModalContent dangerouslySetInnerHTML={term} />
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 840px;
  height: 80vh;
  max-height: 680px;
  z-index: 101;
`;
const ModalTop = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
`;
const ModalContent = styled.div`
  padding: 15px;
  color: #4a4a4a;
  font-size: 12px;
  white-space: pre-line;
  overflow: auto;
  line-height: 20px;
  table td,
  table th {
    border: 1px solid #444;
    padding: 5px;
  }
`;

export default TermComponent;
