import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { PageWrapper } from "../../atoms";
import { Cubes } from "../../molecules";
import { PinkButton } from "../../molecules/buttons";
import header1 from "../../../assets/images/landing/header.png";
import header2 from "../../../assets/images/landing/header2.png";
import header3 from "../../../assets/images/landing/header3.png";
import header4 from "../../../assets/images/landing/header4.png";

import lab1 from "../../../assets/images/landing/lab1.png";
import lab2 from "../../../assets/images/landing/lab2.png";
import lab3 from "../../../assets/images/landing/lab3.png";
import lab4 from "../../../assets/images/landing/lab4.png";

const HeaderImage = ({ mode }) => (
  <>
    {mode === "innofit" ? (
      <>
        {/* 이노핏 */}
        <RightImage1 img1={header1} img2={header2} />
        <RightImageMob1 src={header3} />
        <RightImageMob2 src={header4} />
      </>
    ) : (
      <>
        {/* 이노핏랩 */}
        <RightImage1 img1={lab1} img2={lab2} />
        <RightImageMob1 src={lab3} />
        <RightImageMob2 src={lab4} />
      </>
    )}
  </>
);

const HeaderComponent = () => {
  const [mode, setMode] = useState("innofit");

  // TODO: 추후 모바일 앱 출시 후 활성화 필요
  // useEffect(() => {
  //   // 5초마다 이미지 변경
  //   const modeChange = setInterval(() => {
  //     setMode((prev) => (prev === "innofit" ? "lab" : "innofit"));
  //   }, 5000);
  //   return () => {
  //     clearInterval(modeChange);
  //   };
  // }, []);

  return (
    <HeaderWrapper>
      <PageWrapper>
        <Header>
          <Left>
            <SubTitle>
              {mode === "innofit" ? "당신 속 아름다움에 fit" : "성형 전 필수코스"}
            </SubTitle>
            <Innofit>{mode === "innofit" ? "innofit" : "innofitLab"}</Innofit>
            <Cubes />
            <Content>
              {mode === "innofit" ? (
                // 이노핏
                <>
                  이노핏은 소비자와의 <LabBR2 />
                  신뢰를 약속합니다. <LabBR2 />
                  정품인증서를 <LabBRMob />
                  꼭 확인해 주세요.
                </>
              ) : (
                // 이노핏랩
                <>
                  이노핏랩은 모바일에서&nbsp;
                  <LabBRMob />
                  누구나 <LabBR /> 가상성형이&nbsp;
                  <LabBRMob />
                  가능하도록 <LabBR /> 앱 서비스로&nbsp;
                  <LabBRMob />
                  출시될 예정입니다. <LabBR />
                  <LabBRMob />
                  이노핏랩을 모바일에서&nbsp;
                  <LabBRMob />
                  만나 보세요!
                </>
              )}
            </Content>
            {mode === "innofit" ? (
              // 이노핏
              <Link to="/product">
                <PinkButton name="Product 가기"  />
              </Link>
            ) : (
              // 이노핏랩
              // 이노핏랩 체험 공간 없애면서 가림.
              // <Link to="/lab">
              //   <PinkButton name="이노핏랩 가기" />
              // </Link>
              <Subcontent>
                가상성형 체험공간, 이노핏랩이 <LabBR /> <LabBRMob />
                2022년 5월 14일자로 서비스 <LabBRMob /> 운영을 종료합니다.
              </Subcontent>
            )}
          </Left>
          <Right>
            <HeaderImage mode={mode} />
            <MenuWrapper>
              <MenuLine mode={mode} />
              <MenuList>
                <Menu active={mode === "innofit"} mode={mode} onClick={() => setMode("innofit")}>
                  innofit
                </Menu>
                {/* // TODO: 추후 모바일 앱 출시 후 활성화 필요 */}
                {/* <Menu active={mode === "lab"} mode={mode} onClick={() => setMode("lab")}>
                  innofitLab
                </Menu> */}
              </MenuList>
            </MenuWrapper>
          </Right>
        </Header>
      </PageWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 800px) {
    padding-top: 70px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 50px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 130px;
  @media screen and (max-width: 1100px) {
    padding-bottom: 68px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    padding-bottom: 208px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 220px;
  margin-right: 40px;
  flex-shrink: 0;
  @media screen and (max-width: 800px) {
    padding-top: 52px;
    width: 100%;
  }
`;
const SubTitle = styled.p`
  color: #5a5a5a;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;
const Innofit = styled.p`
  margin-top: 20px;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 80px;
  margin-bottom: 100px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 80px;
  }
`;

const LabBR = styled.br``;
const LabBR2 = styled.br``;
const LabBRMob = styled.br``;
const Content = styled.p`
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  margin-top: 16px;
  margin-bottom: 50px;
  font-weight: 400;
  width: 435px;
  br {
    display: none;
  }
  ${LabBR} {
    display: block;
  }
  ${LabBR2} {
    display: block;
  }
  @media screen and (max-width: 1100px) {
    width: 260px;
    font-size: 24px;
    line-height: 42px;
    br {
      display: block;
    }
    ${LabBR} {
      display: none;
    }
    ${LabBR2} {
      display: block;
    }
    ${LabBRMob} {
      display: block;
    }
  }
  @media screen and (max-width: 800px) {
    font-size: 22px;
    line-height: 38px;
    width: unset;
    br {
      display: none;
    }
    ${LabBR2} {
      display: none;
    }
    ${LabBRMob} {
      display: none;
    }
  }
`;
const Subcontent = styled.div`
  margin-top: -34px;
  border-top: 1px solid #c4c4c4;
  padding-top: 20px;
  color: #5a5a5a;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.05em;

  ${LabBRMob} {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    ${LabBR} {
      display: none;
    }

    ${LabBRMob} {
      display: unset;
    }
  }

  @media screen and (max-width: 800px) {
    ${LabBRMob} {
      display: none;
    }
  }
`

const Right = styled.div`
  display: flex;
  flex-shrink: 1;
  margin-right: -20px;
  align-items: flex-start;
`;
const RightImage1 = styled.div`
  border-radius: 0px 0px 0px 180px;
  width: 710px;
  height: 834px;
  background-image: url(${({ img1 }) => img1});
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: background-image 500ms
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: background-image 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  @media screen and (max-width: 1450px) {
    max-width: 650px;
    height: 760px;
    width: calc(100vw - 470px - 120px - 22px);
  }
  @media screen and (max-width: 1100px) {
    background-image: url(${({ img2 }) => img2});
    width: calc(100vw - 300px - 120px - 20px);
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const RightImageMob1 = styled.img`
  display: none;
  width: calc(100vw - 100px);
  height: auto;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
  @media screen and (max-width: 540px) {
    display: none;
  }
`;
const RightImageMob2 = styled.img`
  display: none;
  width: calc(100vw - 60px);
  height: auto;
  @media screen and (max-width: 540px) {
    display: inline-block;
  }
`;

const MenuWrapper = styled.div`
  margin-left: -6px;
  display: flex;
  width: 66px;
  @media screen and (max-width: 800px) {
    width: 44px;
    margin-left: -4px;
  }
`;
const MenuLine = styled.div`
  transition: height 0.5s;
  height: ${({ mode }) => (mode === "innofit" ? "394px" : "490px")};
  border-left: ${({ mode }) => (mode === "innofit" ? "6px solid #ff4848" : "6px solid #8D5ADF")};
  @media screen and (max-width: 800px) {
    height: ${({ mode }) => (mode === "innofit" ? "319px" : "406px")};
    border-left: ${({ mode }) => (mode === "innofit" ? "4px solid #ff4848" : "4px solid #8D5ADF")};
  }
  @media screen and (max-width: 500px) {
    height: ${({ mode }) => (mode === "innofit" ? "214px" : "310px")};
  }
`;
const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 347px;
  @media screen and (max-width: 800px) {
    margin-top: 277px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 167px;
    margin-left: 10px;
  }
`;
const Menu = styled.div`
  font-family: "Saira Condensed";
  color: ${({ active, mode }) => (!active ? "#C4C4C4" : mode === "innofit" ? "#ff4848" : "#8D5ADF")};
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-rl;
  transform: rotate(-180deg);
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
  & + & {
    margin-top: 20px;
  }
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

export default HeaderComponent;
