import { css } from "styled-components";

export const mobHamburgerCSS = css`
  .nav-fixed {
    z-index: 1;
    position: relative;
  }
  .mo {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }

  .nav-fixed.open .nav-icon-hamburger-1 {
    stroke-dashoffset: 27;
    transition-delay: 0s;
    transition-duration: 0.25s;
  }
  .nav-fixed.open .nav-icon-hamburger-2 {
    stroke-dashoffset: 27;
    transition-delay: 0.125s;
    transition-duration: 0.25s;
  }
  .nav-fixed.open .nav-icon-hamburger-3 {
    stroke-dashoffset: 27;
    transition-delay: 0.25s;
    transition-duration: 0.25s;
  }
  .nav-fixed.open .nav-icon-hamburger-1,
  .nav-fixed.open .nav-icon-hamburger-2,
  .nav-fixed.open .nav-icon-hamburger-3 {
    @media screen and (max-width: 800px) {
      stroke-dashoffset: 16;
    }
  }

  .nav-fixed.dark-mode .nav-icon-color,
  .nav-fixed.open .nav-icon-color {
    fill: #fff;
    stroke: #fff;
  }
  .nav-fixed .nav-icon-color {
    fill: #202040;
    stroke: #202040;
    transition-property: stroke-dashoffset;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .nav-icon-hamburger-1 {
    transition-duration: 0.25s;
    transition-delay: 0.55s;
  }
  .nav-icon-hamburger-2 {
    transition-duration: 0.25s;
    transition-delay: 0.425s;
  }
  .nav-icon-hamburger-3 {
    transition-duration: 0.25s;
    transition-delay: 0.3s;
  }

  .nav-fixed.open .nav-icon-hamburger-close-1 {
    stroke-dashoffset: 0;
    transition-delay: 0.5s;
    transition-duration: 0.25s;
  }
  .nav-fixed.open .nav-icon-hamburger-close-2 {
    stroke-dashoffset: 0;
    transition-delay: 0.625s;
    transition-duration: 0.25s;
  }
  .nav-icon-hamburger-close-1 {
    transition-duration: 0.125s;
    transition-delay: 0.125s;
  }
  .nav-icon-hamburger-close-2 {
    transition-duration: 0.125s;
    transition-delay: 0s;
  }
`;
