import styled from "styled-components";

const Chapter = ({ name }) => (
  <ChapterWrapper>
    {name}
    <Line />
  </ChapterWrapper>
);

const ChapterWrapper = styled.div`
  color: #f78b8b;
  font-size: 15px;
  line-height: 24px;
  font-family: "Saira Condensed";
  display: flex;
  align-items: center;
`;
const Line = styled.div`
  width: 120px;
  height: 1px;
  background: #f78b8b;
  margin-left: 14px;
`;

export default Chapter;
