import { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import "./Layout.css";
import { ToTopButton } from "./molecules";
import { Nav, Footer, MobileNav, MobileNav2 } from "./organisms";
import { useHistory } from 'react-router-dom';

const Layout = ({ children, location }) => {
  const [showNav, setShowNav] = useState(true);
  const { pathname, hash } = location;
  // 메인페이지 이외에는 nav가 fixed라서 nav만큼 padding값으로 밑으로 내림
  const paddingTop = !(location.pathname === "/" || location.pathname === "/mobile");

  useEffect(() => {
    // 페이지 이동하면 맨 위로. 해시링크인 경우엔 예외.
    if(!hash || hash.length === 0) {
      window.scrollTo(0, 0);
    }
    
    let browserTitle = "Main – 개인 맞춤형 코 보형물 이노핏";
    const url = document.location.href;
    
    if (url.includes("lab")) {
      browserTitle = "innofitLab – innofit";
    } 
    
    if (url.includes("story")) {
      browserTitle = "Story – innofit";
    } 
    
    if (url.includes("pr")) {
      browserTitle = "PR – innofit";
    } 
    
    if (url.includes("product")) {
      browserTitle = "Product – innofit";
    } 
    
    if (url.includes("membership")) {
      browserTitle = "Membership – innofit";
    } 
    
    if (url.includes("software")) {
      browserTitle = "Software – innofit";
    } 
    
    if (url.includes("event")) {
      browserTitle = "Event – innofit";
    }
    document.title = browserTitle;
    // nav 안보여야하는 페이지들
    const noNavList = ["/welcome", "/password", "/password-success", "/signup"];
    // 비밀번호 변경페이지에서는 nav 안보이기
    setShowNav(!noNavList.includes(pathname));
  }, [pathname, hash]);

  // 윈도우 사이즈 변경 감지
  const [windowWidth, setWindowWidth] = useState(window.outerWidth)
  useEffect(() => {
    const windowWidthSetter = () => setTimeout(() => setWindowWidth(window.outerWidth), 100)

    window.addEventListener('resize', windowWidthSetter)
    window.addEventListener('orientationchange', windowWidthSetter)

    return () => {
      window.removeEventListener('resize', windowWidthSetter)
      window.removeEventListener('orientationchange', windowWidthSetter)
    }
  }, [])

  // 가로 사이즈 420 기준으로 모바일 리다이렉트
  const history = useHistory()
  useEffect(() => {
    if(windowWidth <= 420 && !pathname.startsWith('/mobile')) {
      if(pathname === '/') {
        history.push('/mobile');
      } else {
        history.push('/mobile' + pathname);
      }
    }

    if(windowWidth > 420 && pathname.startsWith('/mobile')) {
      history.replace(pathname.replace('/mobile', ''));
    }
  }, [history, pathname, windowWidth])

  return (
    <>
      {showNav && (
        <>
          <Nav />
          <MobileNav />
          <MobileNav2 />
        </>
      )}
      <ChildrenWrapper paddingTop={paddingTop}>{children}</ChildrenWrapper>
      {showNav && (
        <>
          <ToTopButton />
          <Footer />
        </>
      )}
    </>
  );
};

const ChildrenWrapper = styled.div`
  padding-top: ${({ paddingTop }) => paddingTop && "65px"};
  @media screen and (max-width: 500px) {
    padding-top: ${({ paddingTop }) => paddingTop && "50px"};
  }
`;

export default withRouter(Layout);
