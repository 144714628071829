import { HashLink } from 'react-router-hash-link';

const HashLinkWithOffset = ({ to, offset = -70, children }) => (
  <HashLink
    to={to}
    scroll={(el) => {
      el.scrollIntoView(true)
      window.scrollBy(0, offset)
    }}
  >
    {children}
  </HashLink>
)

export default HashLinkWithOffset